/* about_inner.css */
  /* css */
  /* .bg_why_we1{
    /*  */
    /* background-color: #cccccc;
    height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-attachment: fixed; 
} */
  .showme{ 
    display: none;
    }
    .solution-head-content{
      padding-top: 12rem !important;
    }
    .showhim:hover .showme{
    display : block;
   
    }
    .showhim:hover .ok{
    display : none;
    }
    /* 2nd circle hover */
    .showme2{ 
      display: none;
      }
      .showhim2:hover .showme2{
      display : block;
     
      }
      .showhim2:hover .ok2{
      display : none;
      }
       /* 3nd circle hover */
    .showme3{ 
      display: none;
      }
      .showhim3:hover .showme2{
      display : block;
     
      }
      .showhim3:hover .ok2{
      display : none;
      }
      /* image css*/
      .vision_inner_img{
        width: 14%;
        display: block;
        margin: 0px auto;
      }
      .vision_inner_img2{
        width: 14%;
       
      }
    
      
.column_vision1 {
  float: left;
  width: 33.33%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row_vision1::after {
  content: "";
  clear: both;
  display: table;
}

/* for original css  */
.col-xs-5ths {
  width: 20%;
  float: left;
}

.b-text {
  display: none;
}
.b-text:hover +.hide_tit_shan{
    display: none
}
.selected {
  display: block;
}
#menu {
  width: auto;
}
#menu * {
  overflow: hidden;
  white-space: nowrap;
  font-size: 30px;
  text-align: center;
}
#menu a {
  display: none;
  width: 0px;
  opacity: 0;
}
#menu span {
  display: inline-block;
  width: 250px;
}
.img_align_inner_mission{
  margin-left: 19%;
  margin-top: -13%;

}
.mgr_left_minus13_inner{
  margin-left: -3%;
}
.title_about_section2{
  text-align: center;
  font-size: 30px
}
.about_inner_ptag{

  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
  letter-spacing: 0.3px;
}
.title_about_section2:after{
  content: "";
  display: block;
  height: 2px;
  width: 60px;
  background-color: #0b69af;
  margin: 0 auto;
  margin-top: 8px;
  margin-bottom: 0px;
  margin-left: 45%;
}
.mrg_bot_inner_5per{
  margin-bottom: 5%;
}
.hvr_fixed_text_inner{
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 14px
}
.hrv_txt_inner_rotate_img{
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 14px;
  
}
.mrg_10_inner{
  margin-top: 20%;
}
.b-text{
  margin-top: -35%;
  /* border: 1px solid rgb(44, 7, 211); */
}
.bg_rotate_img_inner_10{
  background-color: #e4e3e32e;
}

/* zoom image css */
.zoom-effect-hvr_img_inner:hover {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
.zoom-effect-hvr_img_inner{
  
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  
}
/* auto move animations */
#outerspace {
  position: relative;
  height: auto;
  /* background: #fff; */
  color: #fff;
  border-radius: 30px;
  
}
.rocket {
  position: absolute;
  
  -webkit-transition: 3s ease-in;
  -moz-transition: 3s ease-in;
  -o-transition: 3s ease-in;
  transition: 3s ease-in;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 3s;
  animation-name: spin;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
/* 2nd rotation css */
#outerspace2 {
  position: relative;
  height: auto;
  
  color: #fff;
  border-radius: 30px
}
.rocket2 {
  position: absolute;
  
  -webkit-transition: 3s ease-in;
  -moz-transition: 3s ease-in;
  -o-transition: 3s ease-in;
  transition: 3s ease-in;
  -webkit-animation-name: spin2;
  -webkit-animation-duration: 3s;
  animation-name: spin2;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
/* 3rd rotation  */
#outerspace3 {
  position: relative;
  height: auto;
 
  color: #fff;
  border-radius: 30px
}
.rocket3 {
  position: absolute;
  
  -webkit-transition: 3s ease-in;
  -moz-transition: 3s ease-in;
  -o-transition: 3s ease-in;
  transition: 3s ease-in;
  -webkit-animation-name: spin3;
  -webkit-animation-duration: 3s;
  animation-name: spin3;
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
.rocket:hover {
  animation-iteration-count: 0;
}
.rocket2:hover {
  animation-iteration-count: 0;
}
.rocket3:hover {
  animation-iteration-count: 0;
}
@keyframes ripple{
  0% {
    border-radius: 30px
    }
    40% {
      border-radius: 30px;
        box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #52292A, 0 0 0 10px rgba(255,255,255,0.5);
    }
    100% {
        box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #52292A, 0 0 0 10px rgba(255,255,255,0.5);
        transform: scale(1.2);
       border-radius: 30px
    }
}  
@keyframes spin{
  from{
    transform:rotate(0deg);  
  }
  to{
    transform:rotate(360deg);
  }
}
@keyframes spin2{
  from{
    transform:rotate(0deg);  
  }
  to{
    transform:rotate(360deg);
  }
}
@keyframes spin3{
  from{
    transform:rotate(0deg);  
  }
  to{
    transform:rotate(360deg);
  }
}

/* for header border */
.head_bor_inner1{
  font-size: 30px;
  font-weight: 700;
  position: relative;   
   margin-bottom: 0%;
   margin-top: 2%;
}


.head_bor_inner1:after{
  content: "";
  position: absolute;
  width: 118px;
  height: 2px;
  background: #0b69af;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.head_bor_inner3{
  font-size: 30px;
  font-weight: 700;
  position: relative;
  margin-bottom:1%;
}


.head_bor_inner3:after{
  content: "";
  position: absolute;
  width: 15%;
  height: 2px;
  background: #0b69af;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.head_bor_inner4{
  font-size:30px;
  font-weight: 700;
  position: relative;
  /* margin-bottom: 4%; */
}


.head_bor_inner4:after{
  content: "";
  position: absolute;
  width:15%;
  height: 2px;
  background: #0b69af;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* media query */
@media (min-width:280px) and (max-width:1024px){
  .only_mobile_view_inner_rotate{
    display:block!important;
  }
  .only_web_view_inner_rotate{
    display:none;
  }
  .head_bor_inner3{
    font-size: 30px
  }
} 
.only_mobile_view_inner_rotate{
  display:none;
}
/* .mrg_bot_footer_inner8per{
  margin-top: 8%;
} */
.txt_bor_align_about_inner1{
  text-align: left
}
.txt_bor_align_about_inner1{
  font-size: 17px;
  font-weight: 800;
  position: relative;
  margin: 5px 0;
  color: #0b69af;
  letter-spacing: 1px;
  text-align: justify;
  text-align: center;
}
.txt_bor_align_about_inner3{
  font-size: 14px;
  font-weight: 600;
  position: relative;
  margin: 5px 9px;
  letter-spacing: 1px;
  text-align: justify;
}


/* .txt_bor_align_about_inner1:after{
  content: "";
  position: absolute;
  width: 18%;
  height: 2px;
  background: #0b69af;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
} */
.our_assets_inner_about{
  width: 100%;
  padding: 2%;
  background-color: #4965a7;
}
.our_assets_head_inner_about{
  color: #fff;
  text-align: center
}
.our_assets_ptags_inner_about{
  color: #fff;
  text-align: center;
  font-size:20px;
  padding: 3%
}
.our_assets_head_inner_about{
  font-size: 30px;
  font-weight: 700;
  position: relative;
  
}
.our_assets_head_inner_about:after{
  content: "";
  position: absolute;
  width: 23%;
  height: 2px;
  background: #0b69af;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* tab */
@media (min-width:768px) and (max-width:1025px){
.tab_img_size_for_round_img{
  width: 45%;
  display: block;
  margin: 0px auto;
}
}


/* new redesign */
/* .feature-block-v7 { } */
.feature-block-v7.feature-block { margin-bottom: 30px; }
/* .feature-block-v7 .feature-content { } */
.feature-block-v7 .feature-title { margin-bottom: 5px; font-size: 21px; }
/* .feature-block-v7 .feature-text { } */
.feature-block-v7 .feature-icon { background-color: #e1e4fd; color: #3544ee; padding: 18px; font-size: 20px; display: block; text-align: center; width: 60px; height: 60px; margin-bottom: 30px; line-height: 1.5; border-radius: 100%; }
.feature-app-img { position: relative; text-align: center; }
.circle-1 { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 390px; height: 390px; background-color: #3be1a4; color: white; text-align: center; line-height: 100px; border-radius: 50%; font-size: 1.3rem; }
.circle-1:hover { cursor: pointer; }
.circle-1::after, .circle-1::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 390px; height: 390px; background: #3be1a4; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-1::after { background: rgb(59, 225, 164); }
.circle-1::after::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 390px; height: 390px; background: #3be1a4; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-1::before { background: rgb(59, 225, 164); -webkit-animation-delay: -0.5s; animation-delay: -0.5s; }
.circle-2 { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 390px; height: 390px; background-color: #9c4efb; color: white; text-align: center; line-height: 100px; border-radius: 50%; font-size: 1.3rem; }
.circle-2:hover { cursor: pointer; }
.circle-2::after, .circle-2::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 390px; height: 390px; background: #9c4efb; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-2::after { background: rgba(156, 78, 251, .5); }
.circle-2::after::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 390px; height: 390px; background: #3be1a4; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-2::before { background: rgba(156, 78, 251, .5); -webkit-animation-delay: -0.5s; animation-delay: -0.5s; }
.circle-3 { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 390px; height: 390px; background-color: #fb8645; color: white; text-align: center; line-height: 100px; border-radius: 50%; font-size: 1.3rem; }
.circle-3:hover { cursor: pointer; }
.circle-3::after, .circle-3::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 390px; height: 390px; background: #fb8645; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-3::after { background: rgba(251, 134, 69, .5); }
.circle-3::after::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 390px; height: 390px; background: #3be1a4; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-3::before { background: rgba(251, 134, 69, .5); -webkit-animation-delay: -0.5s; animation-delay: -0.5s; }
@-webkit-keyframes grow {
	0% { -webkit-transform: scale(1, 1); transform: scale(1, 1); opacity: 1; }
	100% { -webkit-transform: scale(1.8, 1.8); transform: scale(1.8, 1.8); opacity: 0; }
}
@keyframes grow {
	0% { -webkit-transform: scale(1, 1); transform: scale(1, 1); opacity: 1; }
	100% { -webkit-transform: scale(1.8, 1.8); transform: scale(1.8, 1.8); opacity: 0; }
}
.about_new_chan_img{
  width: 50%
}
/* new's design*/
.about_redes_head{
width:90%;
display: block;
margin:0px auto;
}
.our_mis_vis_about{
  background: #fff;
  padding: 2% 2% 3% 2%;
  box-shadow:0 0 5px #77777742;
  border-radius: 3px;
  margin: 3%;
}
.pad_new_our_vis{
  padding: 1%;
}
.min_vis_jsuti{
  text-align: left;
}
.marg_all_heading_about{
  margin: 20px 0 20px;
}
.collapsibleforabout{
  padding: 7px;
}

/* media query */
@media (min-width: 240px) and (max-width: 767px) {
  .new_img_rotate{
    width: 50%;
    display: block;
    margin: 0px auto;
  }
  .about_inner_ptag{
    text-align: left;
  }
  .txt_bor_align_about_inner1{
    text-align: center;
  }
}
/* media query */
@media (min-width: 768px) and (max-width: 1024px) {
  .new_img_rotate{
    width: 25%;
    display: block;
    margin: 0px auto;
  }
  .about_inner_ptag{
    text-align: left;
  }
}
@media (min-width: 280px) and (max-width: 330px) {
.txt_bor_align_about_inner1{
  text-align: left;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
  .solution-strategic-Partner-alignment {
    display: block !important;
  }
}

@media (min-width: 350px) and (max-width: 470px) {
  .about_inner_ptag {
    text-align: justify !important;
  }
}

@media (min-width: 480px) and (max-width: 550px) {
  .about_inner_ptag {
    text-align: justify !important;
  }
}

@media (min-width: 300px) and (max-width: 349px) {
  .about_inner_ptag {
    text-align: justify !important;
  }
}