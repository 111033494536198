.bg_our_work_inner1 {
  /* background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 50%, #1a1a1a 50%, #3d80e5 50%, #3d80e5 100%); */
  background-image: url(../img_new/videos/banner1.png);
  /* height: 560px; */
  min-height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.h2_head_our_work_tit_new1 {
  text-align: center;
  color: #fff;
  margin-top: 15%;
  font-size: 36px
}

.mrg_left_img_nav {
  width: 75%;
  display: block;
  margin: 0px auto;
}


/* header */

.common_our_serv_head {
  text-align: center;
  padding: 1% 0px 0px 0px;
  text-transform: none;
  font-size: 36px;
 text-transform: uppercase;
  font-weight: 700;
  color: #101f7d;
  margin-top: 0
}

.span_common_our_serv_head {
  content: "";
  position: relative;
  width: 90px;
  height: 2px;
  background: #0b69af;
  bottom: -8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: 2%;
}

.ptag_common_our_work {
  padding: 2% 8%;
}
.link_apps_our_work1{
  padding: 3%;
  font-size: 14px;
  /* padding-left: 5%;
  padding-right: 5%; */
  text-align: justify;
}
.under_link_1{
  color: blue;
  text-decoration: underline

}

/* new setup */


/******************* Timeline Demo - 9 - color-code(#101f7d) *****************/

.main-timeline9 {
  position: relative
}

.main-timeline9:after,
.main-timeline9:before {
  content: "";
  display: block;
  width: 100%;
  clear: both
}

.main-timeline9:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #302124;
  position: absolute;
  top: 0;
  left: 50%
}

.main-timeline9 .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1
}

.main-timeline9 .timeline:after,
.main-timeline9 .timeline:before {
  content: "";
  display: block;
  width: 100%;
  clear: both
}

.main-timeline9 .timeline:first-child:before,
.main-timeline9 .timeline:last-child:before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #101f7d;
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1
}

.main-timeline9 .timeline:last-child:before {
  top: auto;
  bottom: 0
}

.main-timeline9 .timeline:last-child:nth-child(even):before {
  right: auto;
  left: -12px;
  bottom: -2px
}

.main-timeline9 .timeline-content {
  text-align: center;
  margin-top: 8px;
  position: relative;
  transition: all .3s ease 0s
}

.main-timeline9 .timeline-content:before {
  content: "";
  width: 100%;
  height: 5px;
  background: #0b69af;
  position: absolute;
  top: 88px;
  left: 0;
  z-index: -1
}

.main-timeline9 .circle {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: #fff;
  border: 8px solid #0b69af;
  float: left;
  margin-right: 25px;
  position: relative
}

.main-timeline9 .circle span:after,
.main-timeline9 .circle span:before,
.main-timeline9 .circle:before {
  content: "";
  margin: auto;
  position: absolute;
  right: -33px;
  bottom: 0;
  z-index: -1
}

.main-timeline9 .circle:before {
  width: 26px;
  height: 30px;
  background: #0b69af;
  top: 0;
  box-shadow: inset 7px 0 9px -7px #444
}

.main-timeline9 .circle span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  line-height: 160px;
  border: 3px solid #adabab;
  font-size: 86px;
  color: #454344
}

.main-timeline9 .circle span:after,
.main-timeline9 .circle span:before {
  width: 28px;
  height: 50px;
  background: #fff;
  border-radius: 0 0 0 21px;
  top: -54px
}

.main-timeline9 .circle span:after {
  border-radius: 21px 0 0;
  top: 0;
  bottom: -56px
}

.main-timeline9 .content {
  display: table;
  padding-right: 40px;
  position: relative
}

.main-timeline9 .year {
  display: block;
  padding: 10px;
  margin: 10px 0 50px;
  background: #101f7d;
  border-radius: 7px;
  font-size: 26px;
  color: #fff
}

.main-timeline9 .title {
  font-size: 26px;
  font-weight: 400;
  color: #101f7d;
  margin-top: 0;
  text-transform: none;
}

.main-timeline9 .icon span:after,
.main-timeline9 .icon span:before,
.main-timeline9 .icon:before {
  content: "";
  height: 25px;
  margin: auto;
  position: absolute;
  bottom: 0;
  z-index: -1;
  left: -15px
}

.main-timeline9 .description {
  font-size: 14px;
  color: #312e2e;
  text-align: justify
}

.main-timeline9 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  /* border: 4px solid #101f7d; */
  border: 4px solid #0b69af;
  position: absolute;
  top: 78px;
  right: -14px
}

.main-timeline9 .icon:before {
  width: 15px;
  background: #0b69af;
  top: -1px
}

.main-timeline9 .icon span:after,
.main-timeline9 .icon span:before {
  width: 21px;
  background: #fff;
  border-radius: 0 0 21px;
  top: -30px
}

.main-timeline9 .icon span:after {
  border-radius: 0 21px 0 0;
  top: 0;
  left: -15px;
  bottom: -30px
}

.main-timeline9 .timeline:nth-child(2n) .circle,
.main-timeline9 .timeline:nth-child(2n) .timeline-content {
  float: right
}

.main-timeline9 .timeline:nth-child(2n) .circle {
  margin: 0 0 0 25px
}

.main-timeline9 .timeline:nth-child(2n) .circle:before {
  right: auto;
  left: -33px;
  box-shadow: -7px 0 9px -7px #444 inset
}

.main-timeline9 .timeline:nth-child(2n) .circle span:after,
.main-timeline9 .timeline:nth-child(2n) .circle span:before {
  right: auto;
  left: -33px;
  border-radius: 0 0 21px
}

.main-timeline9 .timeline:nth-child(2n) .circle span:after {
  border-radius: 0 21px 0 0
}

.main-timeline9 .timeline:nth-child(2n) .content {
  padding: 0 0 0 40px;
  margin-left: 2px
}

.main-timeline9 .timeline:nth-child(2n) .icon {
  right: auto;
  left: -14px
}

.main-timeline9 .timeline:nth-child(2n) .icon span:after,
.main-timeline9 .timeline:nth-child(2n) .icon span:before,
.main-timeline9 .timeline:nth-child(2n) .icon:before {
  left: auto;
  right: -15px
}

.main-timeline9 .timeline:nth-child(2n) .icon span:before {
  border-radius: 0 0 0 21px
}

.main-timeline9 .timeline:nth-child(2n) .icon span:after {
  border-radius: 21px 0 0
}

.main-timeline9 .timeline:nth-child(2) {
  margin-top: 180px
}

.main-timeline9 .timeline:nth-child(odd) {
  margin: -175px 0 0
}

.main-timeline9 .timeline:nth-child(even) {
  margin-bottom: 80px
}

.main-timeline9 .timeline:first-child,
.main-timeline9 .timeline:last-child:nth-child(even) {
  margin: 0
}

@media only screen and (max-width:990px) {
  .main-timeline9:before {
      left: 100%
  }
  .main-timeline9 .timeline {
      width: 100%;
      float: none;
      margin-bottom: 20px!important
  }
  .main-timeline9 .timeline:first-child:before,
  .main-timeline9 .timeline:last-child:before {
      left: auto!important;
      right: -13px!important
  }
  .main-timeline9 .timeline:nth-child(2n) .circle {
      float: left;
      margin: 0 25px 0 0
  }
  .main-timeline9 .timeline:nth-child(2n) .circle:before {
      right: -33px;
      left: auto;
      box-shadow: 7px 0 9px -7px #444 inset
  }
  .main-timeline9 .timeline:nth-child(2n) .circle span:after,
  .main-timeline9 .timeline:nth-child(2n) .circle span:before {
      right: -33px;
      left: auto;
      border-radius: 0 0 0 21px
  }
  .main-timeline9 .timeline:nth-child(2n) .circle span:after {
      border-radius: 21px 0 0
  }
  .main-timeline9 .timeline:nth-child(2n) .content {
      padding: 0 40px 0 0;
      margin-left: 0
  }
  .main-timeline9 .timeline:nth-child(2n) .icon {
      right: -14px;
      left: auto
  }
  .main-timeline9 .timeline:nth-child(2n) .icon span:after,
  .main-timeline9 .timeline:nth-child(2n) .icon span:before,
  .main-timeline9 .timeline:nth-child(2n) .icon:before {
      left: -15px;
      right: auto
  }
  .main-timeline9 .timeline:nth-child(2n) .icon span:before {
      border-radius: 0 0 21px
  }
  .main-timeline9 .timeline:nth-child(2n) .icon span:after {
      border-radius: 0 21px 0 0
  }
  .main-timeline9 .timeline:nth-child(2),
  .main-timeline9 .timeline:nth-child(even),
  .main-timeline9 .timeline:nth-child(odd) {
      margin: 0
  }
  .h2_head_our_work_tit_new1{
    margin-top: 22%;
  }
  .bg_our_work_inner1{
    height: 207px;
    margin-top: 17%;
  }
  .mrg_left_img_nav{
    display: none
  }
}

@media only screen and (max-width:480px) {
  .main-timeline9:before {
      left: 0
  }
  .main-timeline9 .timeline:first-child:before,
  .main-timeline9 .timeline:last-child:before {
      left: -12px!important;
      right: auto!important
  }
  .main-timeline9 .circle,
  .main-timeline9 .timeline:nth-child(2n) .circle {
      width: 130px;
      height: 130px;
      float: none;
      margin: 0 auto
  }
  .main-timeline9 .timeline-content:before {
      width: 99.5%;
      top: 68px;
      left: .5%
  }
  .main-timeline9 .circle span {
      line-height: 115px;
      font-size: 66px
  }
  .main-timeline9 .circle span:after,
  .main-timeline9 .circle span:before,
  .main-timeline9 .circle:before,
  .main-timeline9 .icon {
      display: none
  }
  .main-timeline9 .content,
  .main-timeline9 .timeline:nth-child(2n) .content {
      padding: 0 10px
  }
  .main-timeline9 .year {
      margin-bottom: 15px
  }
  .main-timeline9 .description {
      text-align: center
  }
}
.img_circle_our_work_tm{
  border-radius: 101px;
    margin-top: -13%;
    
}
.img_circle_our_work_tm:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
  border: 1px solid #f5f5f5;
}
.mt_top_finalchan{
  margin-top: 5%;
}

/* old one  */

.h2_head_our_work_tit_new1{
text-align: center;
padding-top: 28%;
color: #fff;
font-size: 56px;
}
.phone_frame_our_work1{
display: block;
margin: 0px auto;
width: 36%;
/* padding-top: 10%;
padding-bottom: 3%; */
}
.phone_frame_our_work2_keyboard{
position: absolute;
top: 20%;
left: 32%;
width: 36%;
}
.head_tit_h2_ou_work{
margin-top: -25%;
position: absolute;
color: #fff;
font-size: 56px;
left: 9%;
}
.rght_left_pad{
  padding-left: 0px;
  padding-right: 0px;
}
/* .bg_our_work_inner_left{
  background-color: red;
  height: 500px;
  
  width: 50%;
  position: absolute
} */
.our_work__title_head1 {
  font-size: 36px;
  font-weight: 700;
  position: relative;
  text-align: center;
  font-size: 56px;
  margin-bottom: 7%;
  margin-top: 4%;
}

.our_work__title_head1:after {
  content: "";
  position: absolute;
  width: 18%;
  height: 2px;
  background: #dc2e2e;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.our_work_prgt1_title{
  font-size: 36px;
  font-weight: 700;
  position: relative;
  text-align: center;
  font-size: 36px;
  margin-bottom: 7%;
  margin-top: 4%;
  text-transform: none;
}
.our_work_prgt1_title:after {
  content: "";
  position: absolute;
  width: 28%;
  height: 2px;
  background: #dc2e2e;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.our_work_prgt2_title{
  font-size: 36px;
  font-weight: 700;
  position: relative;
  text-align: center;
  font-size: 36px;
  margin-bottom: 7%;
  margin-top: 4%;
  text-transform: none;
}
.our_work_prgt2_title:after {
  content: "";
  position: absolute;
  width: 13%;
  height: 2px;
  background: #dc2e2e;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.our_work_prgt3_title{
  font-size: 36px;
  font-weight: 700;
  position: relative;
  text-align: center;
  font-size: 36px;
  margin-bottom: 7%;
  margin-top: 4%;
  text-transform: none;
}
.our_work_prgt3_title:after {
  content: "";
  position: absolute;
  width: 16%;
  height: 2px;
  background: #dc2e2e;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}  .our_work_prgt4_title{
  font-size: 36px;
  font-weight: 700;
  position: relative;
  text-align: center;
  font-size: 36px;
  margin-bottom: 7%;
  margin-top: 4%;
  text-transform: none;
}
.our_work_prgt4_title:after {
  content: "";
  position: absolute;
  width: 11%;
  height: 2px;
  background: #dc2e2e;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
} 
.our_work_prgt5_title{
  font-size: 36px;
  font-weight: 700;
  position: relative;
  text-align: center;
  font-size: 36px;
  margin-bottom: 7%;
  margin-top: 4%;
  text-transform: none;
}
.our_work_prgt5_title:after {
  content: "";
  position: absolute;
  width: 20%;
  height: 2px;
  background: #dc2e2e;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
} 


.rgth_iride_img0{
  width: 100%;
  display: block;
  margin: 0px auto;
  /* transform: rotate(24deg);
  -ms-transform: rotate(24deg);
  -webkit-transform: rotate(24deg); */
  margin-top: 5%;
}
.rgth_iride_img11{
width: 95%;
  display: block;
  margin: 0px auto;
  /* transform: rotate(24deg);
  -ms-transform: rotate(24deg);
  -webkit-transform: rotate(24deg); */
  /* margin-top: 5%; */
}
.rgth_iride_img1{
  width: 37%;
  display: block;
  margin: 0px auto;
  /* transform: rotate(24deg);
  -ms-transform: rotate(24deg);
  -webkit-transform: rotate(24deg); */
  margin-top: 5%;
}
.rgth_iride_img2{
  width: 50%;
  display: block;
  margin: 0px auto;
  transform: rotate(23deg);
  /* transform: rotate(-26deg);
  -ms-transform: rotate(-26deg);
  -webkit-transform: rotate(-26deg); */
  margin-top: -3%;
}
.rgth_iride_img3{
  width: 50%;
  display: block;
  margin: 0px auto;
  /* transform: rotate(24deg);
  -ms-transform: rotate(24deg);
  -webkit-transform: rotate(7deg); */
  /* margin-top: 15%; */
}
.rgth_iride_imgiportal{
  width: 55%;
  display: block;
  margin: 0px auto;
}
.rgth_iride_imgcatalina{
  width: 45%;
  display: block;
  margin: 0px auto;
}
.rgth_iride_imgicore{
  width: 80%;
  display: block;
  margin: 0px auto;
}
.rgth_iride_img4{
  width: 37%;
  display: block;
  margin: 0px auto;
  /* transform: rotate(-26deg);
  -ms-transform: rotate(-26deg);
  -webkit-transform: rotate(-26deg); */
  margin-top: 5%;
}
.rgth_iride_img5{
  width: 60%;
  display: block;
  margin: 0px auto;
  /* transform: rotate(-26deg);
  -ms-transform: rotate(-26deg);
  -webkit-transform: rotate(-26deg); */
  /* margin-top: -14%; */
}
.left_iride_ptag1{
  padding: 1%;
  font-size: 14px;
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
}
.link_apps_our_work1{
  padding: 3%;
  font-size: 14px;
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
}
.under_link_1{
  color: blue;
  text-decoration: underline

}
/* .mrg_bot_our_work_2sec{
    margin-bottom: 5%;
} */

/* media query */
.dis_none_web_our_work{
  display: none;
}
@media (min-width:280px) and (max-width:1024px){
  .dis_none_web_our_work{
    display: block;
  }
  .web_none_dis_our_work{
    display: none
  }
  .head_tit_h2_ou_work{
    margin-top: -13%;
  position: absolute;
  color: #fff;
  font-size: 36px;
  left: 21%;
  }
  .phone_frame_our_work1{
    padding-bottom: 17%;
    padding-top: 25%;
  }
  .our_work_prgt1_title:after{
    width: 60%;
    left: 38%;
  }
  .our_work_prgt2_title:after{
    width: 39%;
    left: 50%;

  }
  .new_our_work_3:after{
    width: 74%;
    left: 50%;

  }
  .new_our_work_4:after{
    width: 32%!important;
    left: 50%!important;
  }
  .our_work_prgt3_title:after{
    width: 54%;
  left: 51%;
  }
  .our_work_prgt4_title:after{
    width: 22%;
    left: 45%;
  }
  .our_work_prgt5_title{
    font-size: 14px;
  }
  .our_work_prgt5_title:after{
    width: 76%;
    height: 2px;
    background: #dc2e2e;
    bottom: -9px;
    left: 50%;
  }
  .our_work__title_head1:after{
    width: 80%;
    margin-bottom: 2%;
  }
  .h2_head_our_work_tit_new1{
    padding-top: 65%;
  }
  .our_work_prgt1_title{
    margin-top: 25%;
    margin-bottom: 20%;

  }
  .our_work_prgt3_title{
    font-size: 26px;
  }
  .link_apps_our_work1_extra{
    font-size: 14px;
  padding-left: 0%;
  padding-right: 0%;
  }
  .rgth_iride_img3{
    /* margin-top: 15%; */
  /* margin-bottom: 6%; */
  }
  .rgth_iride_img5{
    /* margin-top: -4%; */
  }
}

/* animation for 3Dbox */
.loader {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  perspective:600px;
}
.loader span {
  position:absolute;
  border:10px solid #262626;
  border-radius:4px;
}
.loader span:before{
  content:'';
  position:absolute;
  top:-10px;
  left:-10px;
  background:#ff0;
  width:10px;
  height:50%;
}
.loader span:after{
  content:'';
  position:absolute;
  bottom:-10px;
  right:-10px;
  background:#ff0;
  width:10px;
  height:50%;
}
.loader span:nth-child(1){
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  animation:animate 8s linear infinite;
}
.loader span:nth-child(2){
  position:absolute;
  top:20px;
  left:20px;
  right:20px;
  bottom:20px;
  animation:animate 4s linear infinite;
}
.loader span:nth-child(3){
  position:absolute;
  top:40px;
  left:40px;
  right:40px;
  bottom:40px;
  animation:animate 2s linear infinite;
}



@keyframes animate  {
  0% {
      transform: rotateY(0deg);
  }
  100% {
      transform: rotateY(360deg);
  }
}

/* card view css */
.catlina_bg_our_work_inner{
background-color: #ccc;
}
.slider_ptag_clr{
color: #fff;
padding: 5%;
font-size: 16px
}
/* media query */
@media (min-width:768px) and (max-width:1026px){
.h2_head_our_work_tit_new1{
padding-top: 30%;
}
#myCarousel_our_work{
margin-top: 6%;
}
.our_work__title_head1:after{
width: 25%;
margin-bottom: 0%;
}
.our_work_prgt1_title:after{
width: 18%;
left: 50%;
}
.our_work_prgt1_title{
margin-top: 8%;
margin-bottom: 7%;
}
.our_work_prgt2_title:after{
width: 21%;
}
.our_work_prgt3_title:after{
width: 25%;
  left: 50%;
}
.our_work_prgt2_title:after{
width: 12%;
left: 50%;
}
.our_work_prgt4_title:after{
width: 35%!important;
left: 50%!important;
}
.our_work_prgt5_title:after{
width: 38%;
}
}
.mrg_left_img_nav{
margin-top: 6%;
}

@media (min-width: 280px) and (max-width: 767px) {
  .bg_our_work_inner1{
    width: 100%;
    margin-top: 20%;
    height: 25vh;
    min-height: 25vh;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bg_our_work_inner1 {
    margin-top: 13.4%;
    min-height: 33vh;
  }

  .our-work-container {
    display: block !important;
  }
}
.pph_omgwid{
  width: 35%;
}

.nav-link-underline {
  border-bottom: 1px solid blue;
}

@media (min-width: 300px) and (max-width: 349px) {
  .left_iride_ptag1{
    text-align: justify !important;
  }
}

@media (min-width: 480px) and (max-width: 550px) {
  .left_iride_ptag1{
    text-align: justify !important;
  }
}

@media (min-width: 350px) and (max-width: 470px) {
  .left_iride_ptag1{
    text-align: justify !important;
  }
}