/* contact.css */
.wrapper_contact {
    /* background-image: url('./img_new/footer.jpg'); */
   /* background: #50a3a2;
    background: -webkit-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
    background: linear-gradient(to bottom right, #50a3a2 0%, #53e3a6 100%);*/
    /* position: absolute; */
    top: 50%;
    left: 0;
    width: 100%;
    height: 287px;
    margin-top: 3%;
    overflow: hidden;
  }
  .wrapper_contact.form-success .container h1 {
    -webkit-transform: translateY(85px);
            transform: translateY(85px);
  }
  /* .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 80px 0;
    height: 400px;
    text-align: center;
  }
  .container h1 {
    font-size: 40px;
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    -webkit-transition-timing-function: ease-in-put;
            transition-timing-function: ease-in-put;
    font-weight: 200;
  }
  form {
    padding: 20px 0;
    position: relative;
    z-index: 2;
  }
  form input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: 0;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.2);
    width: 250px;
    border-radius: 3px;
    padding: 10px 15px;
    margin: 0 auto 10px auto;
    display: block;
    text-align: center;
    font-size: 18px;
    color: white;
    -webkit-transition-duration: 0.25s;
            transition-duration: 0.25s;
    font-weight: 300;
  }
  form input:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  form input:focus {
    background-color: white;
    width: 300px;
    color: #53e3a6;
  }
  form button {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: 0;
    background-color: white;
    border: 0;
    padding: 10px 15px;
    color: #53e3a6;
    border-radius: 3px;
    width: 250px;
    cursor: pointer;
    font-size: 18px;
    -webkit-transition-duration: 0.25s;
            transition-duration: 0.25s;
  }
  form button:hover {
    background-color: #f5f7f9;
  } */
  .bg-bubbles {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .bg-bubbles li {
    position: relative;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    bottom: -160px;
    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  .bg-bubbles li:nth-child(1) {
    left: 10%;
  }
  .bg-bubbles li:nth-child(2) {
    left: 20%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 17s;
            animation-duration: 17s;
  }
  .bg-bubbles li:nth-child(3) {
    left: 25%;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
  }
  .bg-bubbles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-duration: 22s;
            animation-duration: 22s;
    background-color: rgba(255, 255, 255, 0.25);
  }
  .bg-bubbles li:nth-child(5) {
    left: 70%;
  }
  .bg-bubbles li:nth-child(6) {
    left: 80%;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .bg-bubbles li:nth-child(7) {
    left: 32%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
  }
  .bg-bubbles li:nth-child(8) {
    left: 55%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
  }
  .bg-bubbles li:nth-child(9) {
    left: 25%;
    width: 10px;
    height: 10px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .bg-bubbles li:nth-child(10) {
    left: 90%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 11s;
            animation-delay: 11s;
  }
  @-webkit-keyframes square {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-700px) rotate(600deg);
              transform: translateY(-700px) rotate(600deg);
    }
  }
  @keyframes square {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-700px) rotate(600deg);
              transform: translateY(-700px) rotate(600deg);
    }
  }

  /* custom css */
  .tet_head{
      color: #fff;
      display: block;
      margin: 0px auto;
      text-align: center;
    margin-top: 8%;
  }
  .text_p_cont{
      color: #fff;
      text-align: center;
      text-align: justify;
  }
  .run_text{
      
      color: #fff
  }
  /* second part */
  .tet_head_sec{
    color: #fff;
    display: block;
    margin: 0px auto;
    text-align: center;
  /* margin-top: 3%; */
  
}
.flt_rgt{
    /* float: right; */
}
  .icons_color_cont{
    color: #fff;
    /* margin-top: 20%; */
    text-indent: -19px;
  }
  .head_cont{
      color: #99ff33;
      /* text-align: center; */
      margin-top: 6%;
  }
  .lft_cont{
      /* left: -5% */
  }
  .flag_col_cont{
      color: #ff9900!important
  }
  @media  (max-width:500px) {
    .text_p_cont{
      text-align: center
      
    }
  .run_text{
    text-align: center;

  }
.hideheader{
    display:none;
    
  }
  .mt_minus{
    margin-top: -1%!important;
  }
  .mt_top{
    margin-top: -5%!important;
  }
  .mb_bot{
    margin-bottom: 0%!important;
  }
  } 
 .mt_top{
   margin-top: 7%;
 }
 .mb_bot{
   margin-bottom: 4%;
 }
 .mt_minus{
   margin-top: -2%;
 }
.mt_bot_contact_new{
  margin-bottom: 9%;
}
.txt_contact_underline{
  text-decoration: underline;
}
 /* media query desktop / laptop /mobile */
 @media (min-width: 768px) and (max-width: 1024px) {
  .mt_top {
    margin-top: -12%!important;
  }
  .mb_bot {
    margin-bottom: 1%;
}
.text_p_cont {
  color: #fff;
  text-align: center;
  /* text-align: justify; */
}
.tet_head{
  font-size: 10px;
  margin-top: 11%;
}
}
/* recreate */
.mt_top_2per{
  margin-top: 2%;
}