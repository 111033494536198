.our_head_inner_us_staffing{
    font-size: 30px;
    font-weight: 700;
    position: relative;
    text-align: center;
    /* font-size: 50px;
    margin-bottom: 7%;
    margin-top: 5%; */
  }
  .our_head_inner_us_staffing:after{
    content: "";
    position: absolute;
    width: 23%;
    height: 2px;
    background: #0b69af;
    bottom: -8px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .us_staffign_ptag_inner1{
    padding:4% 3%;
    font-size: 14px;
    padding-left:5%;
    padding-right:25%;
    text-align: justify
  }
  .us_staffign_ptag_inner1 >strong{
        font-weight: 400!important;
    color: #101f7d!important;
    margin-bottom: 0px;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .main_us_staffing_bg{
      width: 100%;
        display: block;
  } 
 .main_us_staffing_bg:hover{
opacity: 0;
}
.main_us_staffing_bg_hover:hover{
    opacity: 1;
}
.main_us_staffing_bg_hover:hover{
    content: url('../img_new/our_service_inner/us_staffing/us_staff4.jpg')
}
/* #cf_us_staffing {
  position:relative;
  width:100%;
  margin:0 auto;
}

#cf_us_staffing img {
  position:absolute;
  left:0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

#cf_us_staffing img.top:hover {
  opacity:0;
} */
.mor_bot_inner_us_staffing{
  margin-bottom: 2%;
  margin-top: 2%;
}
.card_extra_ptag_inner_us_staffing2{
  display: block;
  padding-left: 25%;
  font-size: 14px;
  color: #0a0a0ac7;
  font-weight: 700;
}
.bullet_us_staffing{
  width: 3%
}
.mrg_top_lft_side_col_staffing{
  /* margin-top: 2%; */
}
/* media query */
@media (min-width:280px) and (max-width:767px){
  .our_head_inner_us_staffing{
    font-size: 37px
  }

  .us_staffign_ptag_inner1{
    text-align: justify !important;
  }
}

@media (min-width: 350px) and (max-width: 470px) {
  .card_extra_ptag_inner_us_staffing2 {
    padding-left: 5%;
  }

  .us_staffign_ptag_inner1{
    text-align: justify !important;
    padding-right: 5%;
  }
}

@media (min-width: 480px) and (max-width: 550px) {
  .card_extra_ptag_inner_us_staffing2 {
    padding-left: 5%;
  }

  .us_staffign_ptag_inner1{
    text-align: justify !important;
    padding-right: 5%;
  }
}

@media (min-width: 300px) and (max-width: 349px) {
  .card_extra_ptag_inner_us_staffing2 {
    padding-left: 5%;
  }

  .us_staffign_ptag_inner1{
    text-align: justify !important;
    padding-right: 5%;
  }
}