/* swiper.css */
/* .swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  .pic_swiper{
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0)!important
  }
  .coverflow__container__1P-xE{
    height: 600px!important;
  }
  .coverflow__container__1P-xE{
    background-color: #fff;
  } */

  /* medai query */
  @media (max-width: 767px) {
    .coverflow__container__1P-xE{
      height: 350px!important;
      margin-top: -60px;
      background: #fff
    }
   
    .coverflow__cover__25-7e{
      height: 200px!important;
      width: 100%!important
    }
    .slider_head_fnt{
      font-size: 17px!important;
      
    }
  }
  
  .coverflow__container__1P-xE{
    background-color: #fff!important;
  } 
  .coverflow__figure__3bk_C{
    box-shadow:0px 0px 0px 0px!important;
  }
  .img_size_box_swiper{
    height:330px!important;
    width:80%!important;
    box-shadow:0px 0px 0px 0px!important;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
   .coverflow__container__1P-xE{

   height: 550px!important;
    }
    }

    .fix_mobileframe{
           /* background-color: red; */
    width: 100%;
    position: absolute;
    /* top: 100%; */
    display: block;
    margin: 0px auto;
    margin-top: -4px;
    z-index: 121;
    left: 39.6%;
    }
    .fix_mobileswiperimg{
      width: 19.5%;
    height: 545px;
    }
    .coverflow__stage__14oqC{
      /* perspective:none!important; */
      margin-left: 5%!important;
    }
    @media (min-width: 280px) and (max-width:767px) {
      .fix_mobileswiperimg{
        width: 31.5%;
        height: 366px;
        margin-left: 39%;
    }
    .fix_mobileframe{
      left: 0.6%;
    }
    .min_vis_jsuti{
      text-align: left!important;
    }
  }
  @media (min-width: 768px) and (max-width:1024px) {
    .fix_mobileswiperimg{
      width: 19.5%;
      height: 548px;
      margin-left: 39%;
  }
  .fix_mobileframe{
    left: 0.6%;
  }
  }

  /* buttons */
    /* new changes for our work */
.for_our_worknew{
  width:90%;
  display: block;
  margin: 0px auto;
}
.img_ourwork_new_logo{
  width: 70%;
  height: 247px;
  object-fit: contain;
  border-radius: 3px;
}
.txt_bor_align_about_inner1_newourwork{
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0px 0 6px;
  color: #0b69af;
  letter-spacing: 1px;
  text-align: justify
}
.head_our_inner_ser{
  margin-left:7%;
  color: rgb(26, 43, 140);
  font-weight: 700;
  font-size: 20px;
}

.custom_icon{
  font-size: 40px;
}
.group_btn_ibra{
  border: 1px solid #000000;
  padding: 7px;
  border-radius: 6px;
  width: 25%;
  color: #fff;
  background: #000000;
  height: 40px;
}
.group_btn_ibra:hover{
  background: #0b69af;
  color: white;
  border-color: #0b69af;
  cursor: pointer;
}
/* .img_google_ply_btn{
  width: 25%!important;
  display: inline!important;
} */
.total_btn{
  display: flex;
}
.padd_rite{
  margin-left: 15px;
}
.group_btn_ibra_icon{
  color: #fff;
  font-size: 22px;
  margin-top: -2px;

}
.fab.pull-left{
  margin-right: .3em;
}
/* .group_btn_ibra_icon:hover{
  color: #fff;
} */

.new_fnt_size{
  font-size: 20px;;
}
.goole_play_cssbutn{
  width: 28%!important;
    margin: 0px!important;
    cursor: pointer;
    height: 55px;
    object-fit: cover;
}
@media (min-width: 280px) and (max-width:340px) {
  
  .goole_play_cssbutn{
    width: 60%!important;
    height: auto;
  }
  .total_btn {
    display: block;
    margin: 0px auto;
}
}
@media (min-width: 341px) and (max-width:767px) {
  
  .goole_play_cssbutn{
    width: 54%!important;
  }
  /* .total_btn {
    display: block;
    margin: 0px auto;
} */
.left_iride_ptag1{
  padding-left: 0%;
}
}

@media (min-width: 350px) and (max-width: 470px) {
  .text-center {
    text-align: center !important;
    position: unset !important;
  }
  .min_vis_jsuti{
    text-align: justify !important;
  }
}

@media (min-width: 300px) and (max-width: 349px) {
	.text-center {
    text-align: center !important;
    position: unset !important;
  }
  .min_vis_jsuti{
    text-align: justify !important;
  }
}

@media (min-width: 480px) and (max-width: 550px) {
	.text-center {
    text-align: center !important;
    position: unset !important;
  }
  .min_vis_jsuti{
    text-align: justify !important;
  }
}