.bg_philosopy1{
    /*  */
    background-color: #cccccc;
    height: 480px;
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    width: 100%;
    /* background-attachment: fixed; */
    padding: 0px;
    object-fit:cover;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.bg_philosopy2{
    /* background-image: url(""); */
    background-color: #cccccc;
    height: 480px;
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    width: 100%;
    /* background-attachment: fixed; */
    padding: 0px;
    object-fit:cover;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.bg_philosopy3{
    /* background-image: url(""); */
    background-color: #cccccc;
    height: 480px;
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    width: 100%;
    /* background-attachment: fixed; */
    padding: 0px;
    object-fit:cover;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
/* zoom image */

.bg_philosopy1:hover  {
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  .bg_philosopy2:hover  {
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  .bg_philosopy3:hover  {
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
.hvr_fixed_text_inner{
    text-align: justify;
    padding: 0%;
    padding-top: 6%;
    font-size: 14px;
    padding-left: 5%;
    padding-right: 8%;
    color: #fff;
  }
  .clr_black_inner{
      color: #000;
  }
  .mrg_10_inner_phis{
    margin-top: 3%;
  }

  /* right & left side colors */
  .left_1_bg_clr_inner{
    background-color: #b75353;
    /* height: 450px; */
    margin-top: 0%;
  }
  .left_2_bg_clr_inner{
    background-color: #fff;
    /* height: 450px; */
    margin-top: 0%;
    /* border: 1px solid #ccc; */
  }
  .left_3_bg_clr_inner{
    background-color: rgb(74, 160, 144);
    /* height: 450px; */
    margin-top: 0%;
  }
  .left_4_bg_clr_inner{
    background-color: #0677b9;
    /* height: 450px; */
    margin-top: 0%;
  }
  .pad_zero_inner{
      padding: 0px
  }
@media (min-width:280px) and (max-width:767px){
    .bg_philosopy1{
        height: auto;
    }
    .bg_philosopy2{
        height: auto;
    }
    .bg_philosopy3{
        height: auto;
    }
    .left_1_bg_clr_inner{
        height: auto;
    padding: 3%;
    }
    .left_2_bg_clr_inner{
        height: auto;
    }
    .left_3_bg_clr_inner{
        height: auto;
        padding: 3%
    }
    .pad_zero_inner_lastone{
      padding: 0px
    }
}
@media (min-width:280px) and (max-width:1024px){
    .only_mobile_view_inner_philosophy{
      display:block!important;
    }
    .only_web_view_inner_philosophy{
      display:none;
    }
    .head_bor_inner3{
      font-size: 30px
    }
    .txt_head_philosophy_inner2{
      text-align: center;
      margin-top: 0%!important;
  }
  .txt_head_philosophy_inner3{
    text-align: center;
    margin-top:  0%!important;
}
.txt_head_philosophy_inner4:after{
  
  margin-left: 25%!important;
}
.txt_head_philosophy_inner1:after{
  margin-left: 30%!important;
}
} 
  .only_mobile_view_inner_philosophy{
    display:none;
  }
  .txt_head_philosophy_inner1{
      text-align: center;
      font-size: 30px
      /* margin-top: 5%; */
  }
  .txt_head_philosophy_inner1:after{
    content: "";
    display: block;
    height: 2px;
    width: 30%;
    background-color: #dc2e2e;
    margin: 0 auto;
    margin-top: 19px;
    /* margin-bottom: 18px;
    margin-left: 7%; */
}
    /* second */
    .txt_head_philosophy_inner2{
        text-align: center;
        margin-top: 5%!important;
        font-size: 30px
    }
    .txt_head_philosophy_inner2:after{
      content: "";
      display: block;
      height: 2px;
      width: 38%;
      background-color: #dc2e2e;
      margin: 0 auto;
      margin-top: 19px;
      /* margin-bottom: 18px;
      margin-left: 8%; */
      }
      /* third */
      .txt_head_philosophy_inner3{
        text-align: center;
        margin-top: 5%!important;
        font-size: 30px;
    }
    .txt_head_philosophy_inner3:after{
      content: "";
      display: block;
      height: 2px;
      width: 30%;
      background-color: #dc2e2e;
      margin: 0 auto;
      margin-top: 19px;
      /* margin-bottom: 18px;
      margin-left: 24%; */
      }
      /* new one */
      .txt_head_philosophy_inner_new{
        text-align: center;
        margin-top: 5%;
        font-size: 30px
    }
    .txt_head_philosophy_inner_new:after{
      content: "";
      display: block;
      height: 2px;
      width: 30%;
      background-color: #dc2e2e;
      margin: 0 auto;
      margin-top: 19px;
      /* margin-bottom: 18px;
      margin-left: 17%; */
      }
      /* new two */
      .txt_head_philosophy_inner_new2{
        text-align: center;
        margin-top: 5%;
        font-size: 30px
    }
    .txt_head_philosophy_inner_new2:after{
      content: "";
      display: block;
      height: 2px;
      width: 30%;
      background-color: #dc2e2e;
      margin: 0 auto;
      margin-top: 19px;
      /* margin-bottom: 18px;
      margin-left: 27%; */
      }
      /* 4th */
      .txt_head_philosophy_inner4{
        text-align: center;
        margin-top: 5%;
        font-size: 30px
    }
      .txt_head_philosophy_inner4:after{
        content: "";
        display: block;
        height: 2px;
        width: 40%;
        background-color: #dc2e2e;
        margin: 0 auto;
        margin-top: 19px;
        /* margin-bottom: 18px;
        margin-left: 12%; */
      }



    .bg_sepr_philosophy_inner3{
        background-color: rgb(74, 160, 144);
    }
    .bg_sepr_philosophy_inner2{
        background-color: #b75353;
    }
    .bg_sepr_philosophy_inner4{
        background-color: #0677b9;
    }
    .clr_white{
        color: #fff
    }
    @media (min-width:768px) and (max-width:1025px){
      .head_bor_inner3:after{
        left: 50%;
      }
      .txt_head_philosophy_inner1:after{
        margin-left: 30%;
      }
      .txt_head_philosophy_inner2:after{
        margin-left: 25%;
        width: 50%;
      }
      .txt_head_philosophy_inner3:after{
        margin-left: 35%;
      }
      .txt_head_philosophy_inner4:after{
        width: 50%;
        margin-left: 26%!important;
      }
      .txt_head_philosophy_inner_new:after{
        margin-left: 35%;
      }
      .txt_head_philosophy_inner_new2:after{
        margin-left: 34%;
      }
    }
    @media (min-width:280px) and (max-width:767px){
      .head_bor_inner1{
        font-size: 40px
      }
      .head_bor_inner1:after{
        width: 50%;
        left: 44%;
      }
      .title_about_section2:after{
        width: 25%;
        margin-left: 34%;
      }
      .head_bor_inner3:after{
        width: 58%;
        left: 50%;
      }
      .txt_head_philosophy_inner1:after{
        margin-left: 17%;
        width: 43%;
      }
      .txt_head_philosophy_inner2:after{
        width: 50%;
        margin-left: 20%;
      }
      .txt_head_philosophy_inner4:after{
        width: 50%;
        margin-left: 25%!important;
      }
      .txt_head_philosophy_inner_new:after{
        width: 55%;
        margin-left: 18%;
      }
      .txt_head_philosophy_inner_new2:after{
        width: 50%;
        margin-left: 24%;
      }
      .head_bor_inner4:after{
        width: 65%;
      }
      .bg_inner_rotate_img111{
        height: 95vh;
      }
      .txt_head_philosophy_inner3:after{
        width: 50%
      }
    }
    /* ibrahim */
.overall_hide{
  padding: 10px 0px 0px 30px;
}
.custom_hide_para{
  padding: 16px;
  border-bottom: 2px solid #edeef1;
    border-right: 2px solid #edeef1;
}
.custom_hide_paras{
  padding: 20px;
    border-right: 2px solid #edeef1;
}
.custom_a_heading{
  font-size: 14px;
  font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;
    text-decoration: underline
}
#btn_one{
  color: #101f7d;
}
.custom_cnt{
  font-size: 14px;
  display: block;
    float: left;
    text-align: justify;
    padding-top: 10px;
}
.custom_cnts{
  font-size: 14px;
  display: block;
    float: left;
    text-align: justify;
    padding-top: 10px;
}


.custom_img_obp{
  height:320px;
    object-fit: cover;
    padding: 10px;
    width: 100%;
    border-radius: 5px
}

.collapsibleforabout >span{
  font-size: 14px;
  background-color: #0b69af;
}
.mrg_btn_collap_new{
  margin-bottom: 2%;
}

@media (min-width: 350px) and (max-width: 470px) {
  .collapsibleforabout p {
    text-align: justify !important;
  }
}

@media (min-width: 480px) and (max-width: 550px) {
  .collapsibleforabout p {
    text-align: justify !important;
  }
}

@media (min-width: 300px) and (max-width: 349px) {
  .collapsibleforabout p {
    text-align: justify !important;
  }
}
