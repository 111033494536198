/* h2{
    text-align:center;
    padding: 20px;
  } */
  /* Slider */
  
  .slick-slide {
      margin: 0px 20px;
  }
  
  .slick-slide img {
      width: 72%;
      /* box-shadow: 5px 1px 2px 2px #ccc; */
      margin: 0px auto;
  }
  
  .slick-slider
  {
      position: relative;
      display: block;
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
              user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
          touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
  }
  
  .slick-list
  {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
  }
  .slick-list:focus
  {
      outline: none;
  }
  .slick-list.dragging
  {
      cursor: pointer;
      cursor: hand;
  }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list
  {
      -webkit-transform: translate3d(0, 0, 0);
         -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
           -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
  }
  
  .slick-track
  {
      position: relative;
      top: 0;
      left: 0;
      display: block;
  }
  .slick-track:before,
  .slick-track:after
  {
      display: table;
      content: '';
  }
  .slick-track:after
  {
      clear: both;
  }
  .slick-loading .slick-track
  {
      visibility: hidden;
  }
  
  .slick-slide
  {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
  }
  [dir='rtl'] .slick-slide
  {
      float: right;
  }
  .slick-slide img
  {
      display: block;
  }
  .slick-slide.slick-loading img
  {
      display: none;
  }
  .slick-slide.dragging img
  {
      pointer-events: none;
  }
  .slick-initialized .slick-slide
  {
      display: block;
  }
  .slick-loading .slick-slide
  {
      visibility: hidden;
  }
  .slick-vertical .slick-slide
  {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
      display: none;
  }
  /* custom css */
  .mgr_top_client1{
      padding-top: 0%!important
  }
  /* media mobile responsive */
  @media (min-width: 280px) and (max-width: 1024px) {
  
    .slick-slide img {
        width: 118%;
        /* box-shadow: 5px 1px 2px 2px #ccc; */
    }
    
  }

  /* extra redesign  */
 

.logos-flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    
    -webkit-flex-flow: row wrap;
    justify-content: space-around;
  }
  
  .logos-flex-item {
    width: 170px;
    height: 80px;
    margin-top: 0%;
    text-align: center;
    cursor: pointer;
  }
  .logo_1strow_mt_clie{
      margin-top: 2%;
      margin-bottom: 3%;
  }
  .logo_secrow_mb_clie{
    margin-bottom: 2%;
}
  @media screen and (max-width: 970px) {
    .logos-flex-item {
    width: 140px;
    height: 80px;
    margin-top: 10px;
    text-align: center;
    margin: 2%;
  }
  .logos-flex-item{
    height: 40px;
  }
  .our_client_redes{
      width: 90%!important;
  }
  }
  
  /* .flex-item img {
    width:200px;
  } */
   .our_client_redes{
    width: 70%;
    display: block;
    margin: 0px auto;
   }
   .our_client_redes_2ndrow{
    width: 55%;
    display: block;
    margin: 0px auto;
   }
   .hover_img_logo:hover{
    border: 1px solid #d4c6c6;
    border-radius: 2px;
   }
   .client_logo_slider_img{
        padding: 6%;
        border:1px solid #eeeeee;
        /* box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); */
   }
   .pad_client_logo{
       padding: 2% 0
   }
  .left_arrow_slick{
    font-size: 47px;
    /* float: right; */
    margin-top: 4%;
    position: absolute;
    left: 100%;
    transform: rotate(180deg);
    cursor: pointer;
    color: #0b69af;
  }
.right_arrrow_slick{
    font-size: 47px;
    /* float: right; */
    margin-top: -7%;
    position: absolute;
    /* left: 100%; */
    transform: rotate(180deg);
    cursor: pointer;
    color: #0b69af;
}
@media (max-width: 767px) {
.left_arrow_slick{
margin-top: 0px;
}
.right_arrrow_slick{
    margin-top: -14%;
}
}

/* for swiper slider */
.left_arrow_slick_swiper{
    font-size: 47px;
    /* float: right; */
    margin-top: -19%;
    position: absolute;
    left: 90%;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    cursor: pointer;
    color: #0b69af;
    z-index: 1234;
}
.right_arrrow_slick_swiper{
    font-size: 47px;
    z-index: 1234;
    /* float: right; */
    margin-top: 5%;
    position: absolute;
    left: 4%;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    cursor: pointer;
    color: #0b69af;
}
@media (max-width: 767px) {
.left_arrow_slick_swiper{
margin-top: 0px;
top: 0;
}
.right_arrrow_slick_swiper{
    margin-top: 0%;
}
.for_our_worknew{
    width: 100%!important;
}
}
/* swiper_slider */
.img_ourwork_new_logo_swiper{
    width: 100%!important;
    height: 305px;
    object-fit: contain;
    border-radius: 3px;
  }

  .today_contact{
      background-color: #eeeeee;
      text-align: center;
      padding: 30px;
  }

  .nav-prepare-img{
    padding-left: 5rem;
  }

  .chk{
    text-align: center;
    font-size: 20px;
    color: #151515;
    padding-top: 10px;
  }
.tdy_p{
  text-align: center!important;
  padding-top: 7px;
  padding-bottom: 9px;
}
#tdy_button{
  border-radius: 5px;
  padding: 5px 25px 5px 25px;
}

  #img_logo{
    width: 125px;
  /*height: 149px;*/
  }

  @media (min-width: 768px) and (max-width: 1024px) {
      .our-client-details-logo {
          display: block !important;
      }
  }