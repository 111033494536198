.bg_contact_right {

  background-color: #3547ab;
  padding: 1%;
  /* background-image: url(../img_new/contact_us_bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 2000px rgb(255, 255, 255);
}

  .error_contact_inner{
    margin-right: 1%;
    font-size: 14px;
    color: #ea1d1d;
    float: right;
  }

  .pad_left_con_contact_inner{
    padding-left: 13px
  }
  .contact_bg_head_inner{
 
    padding: 1%
  }
  .pad_conatiner_contact_inner{
    padding-left: 0px;
    padding-right: 0px;
  }
  .head_txt_contact_inner1{
    color: #101f7d;
    /* padding-top: 10%; */
    text-align: center;
    text-transform: none;
    font-size: 30px
  }

  .contct{
    padding-top: 2%;
  }
  .ptag_contact_inner1{
    color: #101f7d;
    text-align: center;
    /* padding: 2%; */
    font-size: 14px
  }
  /* input_inner_contact box */
.custom_inp_contact_inner1{
  width: 100%!important;
  padding: 17px 8px!important;
  margin: 8px 0!important;
  box-sizing: border-box!important;
  border: 1px solid #cecece!important;;
  /* border-bottom: 2px solid red; */
  background-color: #f3f3f3!important;
  border-radius: 3px!important
}
.react-tel-input .form-control{
  width: 100%!important;
  margin: 8px 0!important;
  box-sizing: border-box!important;
  border: none!important;
  /* border-bottom: 2px solid red; */
  background-color: #f3f3f3!important;
  border-radius: 3px!important
}
.react-tel-input .form-control:focus{
  border-bottom: 4px solid #5880e0!important;
}
.custom_inp_contact_inner1:focus{
  border-bottom: 4px solid #5880e0!important;
}
.mrg_cus_reactphone{
  margin: 8px 0;
}
.mrg_bot_contact_inner{
  margin-bottom: 10%;
}
.span_star_clr_inner{
  color: #e65151
}
.label_contact_inner1{
  font-weight: 400;
  font-size: 16px;
  color:#101f7d
}
.form_align_cen_contact_inner{
  width: 80%;
  display: block;
  margin: 0px auto;
  margin-bottom: 2%;
  margin-top: 2%;
}
.extra_pad_contact_inner_input{
  padding-right: 30px;
  padding-left: 24px;
}
.ptag_new_agree_con_inner{
  color: #0a0a0a;
  text-align: justify;
  padding: 2%;
  padding-top: 0px;
  padding-right: 10%;
  padding-left: 10%;
  font-size: 18px;
  width: 80%;
  display: block;
  margin: 0px auto;
}
.terms_condi_cont_inner{
  text-align: center;
  padding: 3%
}
.recaptch_left_cont_inner{
  left: 37%;
}
.contact_inner_submit_button{
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 9px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}
.contact_inner_submit_button2 {
  background-color: white; 
  color: black; 
  border: 2px solid #0b69af;
  border-radius: 33px;
  display: block;
  margin: 0px auto;
}

.contact_inner_submit_button2:hover {
  background-color: #0b69af;
  color: white;
}
.submit_btn_left_cont_inner{
  left: 41%;
  margin-top: -7%;
}
.card_map_contact_inner{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 325px;
  margin: auto;
  text-align: center;
  font-family: arial;
  padding: 2%;
  line-height: 2;
  position: absolute;
  top: 10%;
  left: 71%;
  background-color: #fff;
  font-size: 12px;
  text-align: justify
}
.find_map_txt_cont_inner{
  text-transform: none;
  margin-bottom: 2%;
  text-align: center;
  color: #0b69af;
  font-size: 20px;
  font-weight: 600;
}
.head_map_inner1{
  margin-bottom: 2%;
  color: #0b69af;
  font-weight: 600;
  font-size: 16px;
}
.ptag_fs_cont_inner_map{
  font-size: 12px !important;
  display: grid
}
.card_address_contact_inner{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 100%;
  /* margin: auto; */
  text-align: center;
  font-family: arial;
  padding: 2%;
  line-height: 2;
  margin:6% 10%;
  /* height: 265px; */
  height: auto;
  border: 1px solid #ddd;
  background-color: #0b69af;
}
.card_address_contact_inner h5 {
  color: #fff;
}
.card_address_contact_inner:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd ;
}
.ptag_fs_cont_inner_map_card{
  font-size: 14px;
  padding: 3%;
  padding-left: 5%;
  text-align: justify;
}
.ptag_fs_cont_inner_map_card {
  color: #fff
}
.head_map_inner2_bot{
  margin-bottom: 2%;
  margin-top: 6%;
}
.atag_under_cont_inner:hover{
  text-decoration: underline;
  color: #7373e4
}
.ptag_fs_cont_inner_map_card2{
  font-size: 14px;
padding-left: 5%;
  text-align: justify;
}

/* media query */
@media (min-width: 280px) and (max-width: 1024px) {
  
  /* .head_txt_contact_inner1{
     padding-top: 50%; 
  } */
  .submit_btn_left_cont_inner{
    left:-1%;
    margin-bottom: 10%;
  }
  .recaptch_left_cont_inner{
    left:1%
  }
  .card_map_contact_inner{
    position: relative;
    left: 0;
    margin-top: 6%;
    text-align: center;
    box-shadow:0 0 0 0;
  }
  .card_address_contact_inner{
    margin-top: 2%;
    margin-bottom: 4%;
   margin-left: 0%;
   margin-right: 0%;
  }
  .pad_conatiner_contact_inner1{
    padding-right: 5px;
    padding-left: 5px;
  }
  .contact_inner_submit_button{
    margin: 9px auto;
    display: block;
  }
  .form_align_cen_contact_inner{
    width: 100%;
  }
}

/* popup css */
.modal_popup_thansk {
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  width: 100%;
  height: 100%;
  padding-bottom: 22%;
  position: fixed;
  padding-bottom: 22%;

}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 65%;
  padding: 5%;
  text-align: center;
  /* min-height: 300px; */
  margin: auto;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  /* transition: 0.3s; */
  margin-top: 140px;
}
.modal_h2tag_fs{
  font-size: 30px
}
.btn_modal_popup{
  width: 21%;
  min-height: 45px;
  border-radius: 120px;
  margin: auto;
  background-color: rgb(8, 108, 155);
  margin-top: 6%;
  color: #fff;
  font-size: 20px
}
.btn_modal_popup:hover{
  background-color: #fff;
  border:2px solid rgb(8, 108, 155);
}
.span_btn_link{
  margin: auto;
  margin-top: 3%;
  font-size: 25px;
}

/* phone number css */
.phone-number {
  display: flex;
}

.phone-number--country {
  flex: 1 1 30%;
}

.phone-number--number {
  flex: 1 1 70%;
}

.Select-menu-outer {
  width: 200px;
}

.message {
  font-weight: bold;
  color: brown;
}
select {
  color: red
}
option{
  color: red
}
/* media query */
@media (min-width: 280px) and (max-width: 767px) {
  .btn_modal_popup{
    width: 47%;
  }
  .modal_popup_thansk{
    height: 800px;
  }
  .modal_h2tag_fs{
    font-size: 23px;
  }
  .modal-content{
    width: 90%;
    margin-top: 50%;
  }
  .header_top_mobile{
    margin-top: 9%;
  }
}
/* input valid */
/* input:valid {
  color: green;
}
input:invalid {
  color: red;
} */
/* for loding text */
.loading_text_form{
  font-size: 30px;
  text-align: center;
  display: block;
  color: #099109;
}
@media (min-width: 768px) and (max-width: 1025px) {
.head_txt_contact_inner1{
  padding-top: 15%;
}
.card_address_contact_inner{
  height: 370px
}
}

#dis_none_con_status { display:none; }

.bg_our_contactus_inner1{
  /* background: #31383f; */
  height: 77px;
}
.sentheaddiv{
  margin: 10% 15%;
    text-align: center;
}
.txt_sceessentmsg{
  color: #03a84e;
  line-height: 45px;
}
.contact-submit-btn{
  background-color: #0d41ba;
  color: #fff;
}