
::selection {
    background: #555;   
    color: #fff;
}
::-moz-selection {
    background: #555;    
    color: #fff;
}
/* body {
    background: #151515;
    padding: 0;
    margin: 0 auto;
    min-height: 100vh;
    font-family: 'Yantramanav', Arial, sans-serif;
    color: #151515;
} */
article,
aside,
details,
figcaption,
footer,
header,
hgroup,
nav,
section {
    display: block;
}
/* a {
    color: #df1f29;
    text-decoration: none;
} */
blockquote {
    padding: 10px 20px;
    color: #151515;
    font-size: 1.4em;
    font-style: italic;
    font-weight: 300;
    margin: 0 auto 30px;
}
ul {
    list-style-type: none;
    list-style: none;
    padding-left: 0 !important;
    -webkit-padding-start: initial !important;
    -moz-padding-start: initial;
}
menu,

ul {
    padding: 15px!important;
    /* padding: 0 !important; */
}
button {
    background: transparent;
    border: none;
}
button:focus {
    outline: none;
}

#sm_btn{
        font-size: 13px;
        font-weight: 600;
        padding: 8px 9px;
        line-height: 15px;
        border-radius: 15px;
        background: #38479a;
        outline: none;
        border: 0;
        -webkit-transition: color 400ms, background-color 400ms, border-color 400ms;
        transition: color 400ms, background-color 400ms, border-color 400ms;
        transition: color 400ms, background-color 400ms, border-color 400ms;
        position: relative;
}
input,
textarea {
    font-family: 'Yantramanav', Arial;
}
textarea {
    padding: 14px;
}
input:focus,
textarea:focus {
    outline: none !important;
    border: 1px solid #df1f29;
    box-shadow: none;
}
input[type="submit"] {
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
input[type="submit"]:focus {
    outline: none;
}
img {
    width: 100%;
}

/* 1.1 Typography */

h1,
h2,
h4,
h5,
h6 {
    color: #151515;
    margin-top: 0;
    /* text-transform: uppercase; */
}
h1 {
    font-size: calc(80px + 1vw);
}
h2 {
    font-size: calc(36px + 16 * (100vw - 992px) / 1508);
}
h3 {
    font-size: calc(28px + 16 * (100vw - 992px) / 1508);
    /* margin-bottom: 20px; */
}
h4 {
    font-size: calc(24px + 16 * (100vw - 992px) / 1508);
    font-weight: 300;
}
h5 {
    font-size: 20px;
    font-weight: 400;
}
h6 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: calc(10px + 10 * (100vw - 992px) / 1508);
}
p {
    /* font-size: calc(18px + 2 * (100vw - 992px) / 1508); */
    line-height: 1.8;
    font-weight: 300;
}

/* 1.2 Specials Classes */

.c-white {
    color: #fff !important;
}
.navbar-white {
    color: #fff;
    background-color: transparent !important;
}
.center-block {
    display: flex;
    justify-content: center;
}
.align-left {
    text-align: left;
}
.align-right {
    text-align: right;
}
.align-center {
    text-align: center;
    display: block;
}
.socials li {
    display: inline-block;
}
.share-links a {
    font-size: 19px;
    padding: .5em;
}
[class*="socicon-"] {
  display: inline-block;
  color: inherit;
  margin: 0;
  transition: opacity .3s ease;
}
#contact [class*="socicon-"] {
  padding-right: .7em;
}
[class*="socicon-"]:hover {
  opacity: .4;
}
/* 1.3 Button */

.ms-button {
  color: #151515;
  display: inline-block;
  position: relative;
  padding: .5em 1em;
  margin-bottom: 0;
  font-weight: 400 !important;
  font-size: 14px;
  letter-spacing: .15em;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  text-decoration: none;
  border: solid 1px;
  transition: color .4s ease;
}
.ms-button:after {
    position: absolute;
    display: block;
    content: attr(data-title);
    padding: 7px 0;
    font-size: 14px;
    left: 0;
    top: 0;
    width: 0%;
    background: #df1f29;
    color: #fff;
    text-indent: 1rem;
    transition: all .3s ease;
    overflow: hidden;
    border: none;
    bottom: 0;
}
.ms-button:hover {
  color: #df1f29;
}
.ms-button:active::after,
.ms-button:focus::after,
.ms-button:hover::after {
    width: 100%;
    padding-right: 25px;
}

.ms-btn--slider {
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 11em;
  font-weight: 600;
  letter-spacing: .15em;
}
.ms-btn--slider::before{
    content: '';
    height: .1rem;
    width: 2rem;
    margin-right: .7rem;
    background: #df1f29;
    transition: width .3s ease;
}
.ms-btn--slider:hover::before {
  width: 3rem;
}

/**
 * 2. Header
 */

.ms-header {
    position: absolute;
    width: 100%;
    background-color: #fff;
    /* z-index: 3; */
}
/* 2.1 Menu */

.ms-logo {
    position: relative;
    z-index: 13;
    height: 90px;
}
.logo-dark,
.logo-light {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .3s ease .4s;
}
.ms-logo img {
    width: auto;
    height: 95%;
}
.logo-dark.current,
.logo-light.current {
    opacity: 1;
}
.logo-dark.active,
.logo-light.active {
    opacity: 1;
    transition: opacity .1s;
}
.ms-nav {
    padding: 2em 3em;
    color: inherit;
}

/* 2.2 Burger button */

.hamburger {
    margin-right: 0;
    margin-left: auto;
    overflow: visible;
    cursor: pointer;
    z-index: 13;
    border: 0;
    transition-timing-function: linear;
    transition-duration: .3s ease;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
}
.navbar-white .hamburger-label {
    color: #fff;
}
.hamburger-label {
    display: block;
    font-family: 'Yantramanav', Arial;
    font-size: 16px;
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    left: -4em;
    padding-right: 4em;
    text-transform: uppercase;
    line-height: 1.5em;
    transition: color .3s ease .4s;
    color: #151515;
}
.hamburger-label:hover{
    /* color:red;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:red;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease; */
}
.hamburger.is-active .hamburger-label {
    color: #fff !important;
    transition: color .1s ease;
}
.hamburger-box {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
    vertical-align: middle;
}
.hamburger-inner {
    display: block;
    top: 0;
}
.navbar-white .hamburger-inner,
.navbar-white .hamburger-inner:after,
.navbar-white .hamburger-inner:before {
    background-color: #fff;
}
.hamburger-inner:after,
.hamburger-inner:before {
    display: block;
    content: '';
}
.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #151515;
    transition: all .3s ease .5s;
}
.hamburger-inner:before {
    top: 8px;
    left: -8px;
}
.hamburger-inner:after {
    top: 16px;
    width: 26px;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after {
    background-color: #fff;
    transition-delay: .1s;
}
.hamburger.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 8px, 0) rotate(45deg);
    transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger.is-active .hamburger-inner:before {
    opacity: 0;
    transition: opacity .3s ease 0s
}
.hamburger.is-active .hamburger-inner:after {
    width: 30px;
    -webkit-transform: translate3d(0, -16px, 0) rotate(-90deg);
    transform: translate3d(0, -16px, 0) rotate(-90deg);
}

/* 2.3 Collapse navigation */

.ms-nav {
    display: flex;
    align-items: center;
}
.height-full-viewport {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 0%;
    background-color: #151515;
    z-index: 12;
    overflow: hidden;
    transition: height .8s cubic-bezier(0.3, 1, 0.3, 1) .1s;
}
.ms-navbar {
    z-index: 6;
    margin: 0 auto;
    display: block;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    margin-top: 8%;
}
.ms-nav.is-visible .height-full-viewport {
    height: 100vh;
    transition: height 1s ease;
}
.nav-item {
    cursor: pointer;
    padding: .75em;
    opacity: 0;
    width: 240px;
    margin-left: 3em;
    transition: opacity .3s ease;
}
.ms-navbar a {
    display: block;
}
.ms-navbar .ms-btn {
    display: inline-block;
    text-transform: uppercase;
    line-height: 1;
    padding: 0 0.25em;
    position: relative;
}
.ms-navbar .ms-btn {
    font-size: 1.55em;
    letter-spacing: .02em;
    color: #fff;
    font-weight: 700 !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.ms-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #df1f29;
    height: .1rem;
    top: 50%;
    width: 0;
    transition: width .3s ease;
}
.ms-btn2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    height: .2rem;
    top: 50%;
    width: 0;
    transition: width .3s ease;
}

.nav-item__label {
    position: relative;
    display: block;
    margin: 0.5em 0 0 0;
    margin-top: 0.25em;
    padding: 0.5em;
    /* color: #555; */
    color: #918d8d;
    transition: color .3s ease;
    text-decoration: none;
}
.nav-item:hover .nav-item__label {
    color: #9a9a9a;
}
.is-visible .nav-item {
    opacity: 1;
    transition: opacity 2s cubic-bezier(0.16, 1.08, 0.38, 0.98) .3s;
}
.nav-item .ms-btn {
    transition: all .3s ease;
}
.nav-item:active .ms-btn,
.nav-item:focus .ms-btn,
.nav-item:hover .ms-btn {
    color: #df1f29;
    opacity: 1;
    padding-left: 2.4rem;
}
.nav-item:active .ms-btn::before,
.nav-item:focus .ms-btn::before,
.nav-item:hover .ms-btn::before {
    width: 2rem;
}

/**
 * 3. Pages Style
 */

.ms-preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    background-color: #151515;
}
.ms-main-container {
    position: relative;
    width: 100%;
    /* min-height: 100vh; */
    opacity: 0;
    background-color: #fff;
}
.ms-container {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 12em;
}
.ms-section__block {
    width: 85%;
    max-width: 88em;
    margin: 0 auto 6em;
}
.ms-page-title span {
    text-transform: uppercase;
    letter-spacing: .15em;
    color: #df1f29;
}
.page-header {
    text-transform: uppercase;
    position: relative;
    margin: 15px 0;
}
.page-desc {
    width: 50vh;
    font-size: 1.5em;
    line-height: 1.5;
    color: #151515;
    letter-spacing: .05em;
}

/**
 * 4. Home Slider
 */

.home-slider {
    padding-top: 0 !important;
}
.swiper-container {
    width: 100%;
    height: 100vh;
}
.swiper-slide {
    overflow: hidden;
}
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 1;
    background: -moz-linear-gradient(top, black 0, transparent 50%, transparent 63%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-linear-gradient(top, black 0, transparent 50%, transparent 63%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to bottom, black 0, transparent 50%, transparent 63%, rgba(0, 0, 0, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#c9000000', GradientType=0);
    transition: all .65s ease;
}
.swiper-slide-duplicate-active .overlay,
.swiper-slide-active .overlay {
    opacity: .4;
}
.slide-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
.slide-inner--image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: .2;
    transform: scale(1.07);
    -webkit-transition: opacity .6s ease-in-out, transform 2s ease .7s;
    -moz-transition: opacity .6s ease-in-out, transform 2s ease .7s;
    transition: opacity .6s ease-in-out, transform 2s ease .7s;
}
.swiper-slide-duplicate-active .slide-inner--image,
.swiper-slide-active .slide-inner--image {
    opacity: 1;
    transform: scale(1);
}

/* 4.1 Slider navigation buttons */

.swiper-button-prev,
.swiper-button-next {
    text-transform: uppercase;
    color: #fff;
    letter-spacing: .2em;
    line-height: 2em;
    font-size: 12px;
    width: auto;
    height: 24px;
    background-image: none !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.swiper-button-prev {
    padding-left: 6em;
    left: 0;
    -webkit-transition: padding-left .3s ease;
    -moz-transition: padding-left .3s ease;
    transition: padding-left .3s ease;
}
.swiper-button-next {
    padding-right: 6em;
    right: 0;
    -webkit-transition: padding-right .3s ease;
    -moz-transition: padding-right .3s ease;
    transition: padding-right .3s ease;
}
.swiper-button-prev::after {
    content: "";
    width: 4em;
    height: 1px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    -webkit-transition: width .3s ease;
    -moz-transition: width .3s ease;
    transition: width .3s ease;
}
.swiper-button-next::after {
    content: "";
    width: 4em;
    height: 1px;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: calc(50% - 1px);
    -webkit-transition: width .3s ease;
    -moz-transition: width .3s ease;
    transition: width .3s ease;
}
.swiper-button-prev:hover {
    padding-left: 90px;
}
.swiper-button-prev:hover::after {
    width: 75px;
}
.swiper-button-next:hover {
    padding-right: 90px;
}
.swiper-button-next:hover::after {
    width: 75px;
}

/* 4.2 Slider inner info */

.slide-inner--info {
    position: absolute;
    width: auto;
    min-height: 50px;
    bottom: 3em;
    padding-left: 3em;
    color: #fff;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.swiper-slide-active .slide-inner--info {
    opacity: 1;
    transition: opacity 1s ease 1s;
}
.slide-inner--info h1 {
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1em;
    color: #fff;
}
.slide--info__link {
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border-bottom: solid 2px;
}
.swiper-slide--bottom {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

/* 4.3 Pagination */

.expanded-timeline {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
    z-index: 3;
    bottom: 2.8em;
    right: 3em;
    width: 40vh;
}
.swiper-pagination {
    z-index: 4;
    width: 85%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(255, 255, 255, .5);
}
.swiper-pagination-progressbar-fill {
    background: #fff !important;
}
.expanded-timeline__counter span {
    text-align: center;
    margin-left: 1.8em;
    margin-right: 100%;
    padding: .4em 0;
    color: #fff;
    display: block;
}
.scroll-message {
    position: absolute;
    right: 0;
    color: #fff;
    opacity: .5;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    letter-spacing: .15em;
}
.scroll-svg {
    width: 24px;
    margin: 0 auto 1.2em;
    display: block;
}

/**
 * 5. Albums
 */

.filtr-container {
    transition: height .5s ease-out;
    overflow: hidden;
}
.filtr-btn {
    display: inline-block;
    margin-bottom: 2.4em;
}
.filtr-btn li {
    cursor: pointer;
    display: inline-block;
    opacity: .5;
    margin-bottom: 15px;
    transition: opacity .3s ease;
}
.filtr-btn h6 {
    margin-bottom: 0;
    padding-right: 1.2em;
}
.filtr-btn li.active {
    opacity: 1;
}
.filtr-btn li:hover {
    opacity: 1 !important;
}
.album-item {
    padding-bottom: 1.8em;
}
.album-item a {
    padding-top: 60%;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 9;
}
.album-item__img {
    position: absolute;
    width: 100.5%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: 50% 50%;
    background: no-repeat;
    background-position: center;
    background-size: cover;
    transform: scale(1);
    transition: transform .3s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.album-item__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100.5%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
}
.album-item__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1;
    overflow: hidden;
    opacity: 0;
    transition: opacity .3s ease;
}
.album-item__overlay h5 {
    position: relative;
    color: #fff;
    margin: 0;
    max-width: 100%;
    padding: 1em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    transform: scale(1.5);
    opacity: 0;
    transition: all .3s ease;
}
.album-item:hover .album-item__img {
    transform: scale(1.1);
}
.album-item:hover .album-item__cover,
.album-item:hover .album-item__overlay h5 {
    opacity: 1;
    transform: translateZ(0);
}

/**
 * 6. Gallery
 */

.ug-thumb-overlay:hover::before {
    top: 15px;
    right: 15px;
}
.ug-thumb-overlay:hover::after {
    bottom: 15px;
    left: 15px;
}
.ug-thumb-image {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    transition: transform .3s ease, opacity 2s cubic-bezier(0.16, 1.08, 0.38, 0.98) 0s !important;
}
.ug-thumb-image {
    transform: scale(1) !important;
}
.ug-thumb-wrapper,
.post-item {
    pointer-events: none;
}
.ug-thumb-wrapper.ug-tile.ug-tile-clickable.ug-thumb-ratio-set:hover .ug-thumb-image {
    transform: scale(1.1) !important;
}

/**
 * 7. Blog
 */

.ms-right-sidebar {
    display: inline-block;
}
.ms-right-sidebar h5 {
    margin-bottom: 15px;
}
.ms-right-sidebar a {
    display: block;
    border-top: solid 1px rgba(103, 103, 103, .3);
    padding: 15px 0;
}
.ms-right-sidebar a:hover .post-item__title,
.ms-right-sidebar a:hover .post-item__title {
    color: #df1f29;
}
.ms-right-sidebar .post-item__title {
    margin-bottom: 0;
    transition: color .3s ease;
}
.ms-right-sidebar .post-item__date {
    margin-top: .3em;
}
#ms-blog-post img {
    margin-bottom: 30px;
}
.post-item {
    margin-bottom: 3em;
    padding-right: 3em;
}
.post-item:nth-child(2n) {
    padding-right: 15px;
    padding-left: 3em;
}
.post-item__img {
    position: relative;
    overflow: hidden;
}
.album-item__overlay::before,
.post-item__img::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 1;
    background-color: rgb(240, 240, 240);
    transition: opacity 2s cubic-bezier(0.16, 1.08, 0.38, 0.98) 1.2s;
}
.loaded .album-item__overlay::before,
.loaded .post-item__img::before {
    opacity: 0;
}
.post-item__img img {
    width: 100%;
    height: auto;
    max-height: 35em;
    padding: 0;
    display: block;
}
.post-item__img::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background: rgba(231, 55, 39, 0);
    background: -moz-radial-gradient(center, ellipse cover, rgba(231, 55, 39, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(231, 55, 39, 0)), color-stop(100%, rgba(0, 0, 0, 1)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(231, 55, 39, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background: -o-radial-gradient(center, ellipse cover, rgba(231, 55, 39, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(231, 55, 39, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background: radial-gradient(ellipse at center, rgba(231, 55, 39, 0) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e73727', endColorstr='#000000', GradientType=1);
    transition: opacity .3s ease;
}
.post-item__info {
    position: relative;
    margin: 2.5em 0 0 2.5em;
}
.post-item__title {
    width: 100%;
    max-width: 400px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 20px;
}
.post-item__date {
    margin-left: 0;
    margin-right: auto;
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
}
.post-item__link {
    position: absolute;
    width: auto;
    bottom: 0;
    right: 0;
    padding-right: 3em;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .12em;
    -webkit-transition: padding-right .3s ease;
    -moz-transition: padding-right .3s ease;
    transition: padding-right .3s ease;
}
.post-item__link::after {
    content: '';
    width: 2em;
    height: 1px;
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    background: red;
    -webkit-transition: width .3s ease;
    -moz-transition: width .3s ease;
    transition: width .3s ease;
}
.post-item:hover .post-item__img::after {
    opacity: .7;
}
.post-item:hover .post-item__link::after {
    width: 3em;
}
.post-item:hover .post-item__link {
    padding-right: 4em;
}
.ms-pagination {
    padding: 0;
    display: flex;
    justify-content: center;
}
.page-item {
    height: auto;
    position: relative;
    display: inline-block;
    margin: 0 .5em;
    border-right: none !important;
}
.page-item:last-child {
    border-right: solid 1px rgba(0, 0, 0, .1);
}
.page-link {
    padding: 1em;
    display: block;
    line-height: 1em;
}

/**
 * 8. About
 */

.about__info {
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.about__info h4 {
    margin: 1em 0;
}
#about .row {
    margin-bottom: 9em;
}

/**
 * 9. Contact
 */

.form-group {
    position: relative;
    margin: 0;
    transition: border-color .3s ease;
}
.form-control {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 1em;
    height: 34px;
    font-size: calc(16px + 2 * (100vw - 992px) / 1508);
    color: #151515;
    font-weight: 300;
    margin-bottom: 2em;
    background-image: none;
    border: 1px solid #555;
}
#message {
    min-height: 150px;
    max-height: 150px;
    max-width: 100%;
    min-width: 100%;
    padding: 10px 14px;
}
label.error {
    position: absolute;
    bottom: 1.2em;
    font-size: 12px;
    color: #df1f29;
}

/**
 * 10. Footer
 */

footer {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    /* background: #fff; */
}
.ms-footer {
    width: 85%;
    max-width: 88em;
    margin: 0 auto;
    display: flex;
    font-size: 16px;
    align-items: center;
}
.ms-footer .copyright {
    padding: 1.2em 0;
    margin-left: 0;
    margin-right: auto;
}
.ms-footer .socials {
    padding: 0;
    margin-left: auto;
    margin-right: 0;
}
.ms-footer .socials a {
    padding-left: 15px;
}

/**
 * 11. Responsive Rules
 */

@media only screen and (max-width: 805px) {
    h1 {
        font-size: calc(48px + 1vw);
    }
    h6 {
        font-size: 16px;
    }
    blockquote {
        margin: 0 auto 15px;
        font-size: 1em;
    }
    .expanded-timeline {
        width: 50vh;
    }
    .ms-nav {
        padding-top: 1.4em;
    }
    .ms-navbar .ms-btn {
        font-size: 1.4em;
    }
    .ms-navbar {
        position: relative;
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
    }
    .nav-item {
        display: inline-block;
        width: auto;
        margin-left: 0;
    }
    .nav-item__label {
        display: none;
    }
    .ms-nav {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 1.4em;
    }
    .overlay {
        background: -moz-linear-gradient(top, black 0, transparent 35%, transparent 45%, rgba(0, 0, 0, 0.9) 100%);
        background: -webkit-linear-gradient(top, black 0, transparent 35%, transparent 45%, rgba(0, 0, 0, 0.9) 100%);
        background: linear-gradient(to bottom, black 0, transparent 35%, transparent 45%, rgba(0, 0, 0, 0.9) 100%);
        filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#c9000000', GradientType=0);
    }
    .swiper-button-prev,
    .swiper-button-next {
        top: 50vh;
    }
    .swiper-button-prev {
        padding-left: 3em;
    }
    .swiper-button-next {
        padding-right: 3em;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        width: 2em;
    }
    .swiper-button-prev:hover {
        padding-left: 3em;
    }
    .swiper-button-prev:hover::after {
        width: 2em;
    }
    .swiper-button-next:hover {
        padding-right: 3em;
    }
    .swiper-button-next:hover::after {
        width: 2em;
    }
    .expanded-timeline {
        display: none;
    }
    .slide-inner--info {
        width: 100%;
        text-align: center;
        min-height: 50px;
        bottom: 10vh;
        padding-left: 0;
        color: #fff;
        z-index: 1;
        opacity: 0;
        transition: opacity 1s ease;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .ms-btn--slider {
        margin: 15px auto;
        display: inline-block;
        letter-spacing: .15em;
        background: #df1f29;
        color: #fff;
        padding: .5em 0;
    }
    .ms-btn--slider::before {
        content: '';
        display: none;
        width: 0;
    }
    .ms-container {
        padding-top: 6em;
        padding-bottom: 3em;
    }
    .ms-container.home-slider {
        padding-bottom: 0 !important;
    }
    .ms-section__block {
        width: auto;
        margin: 0 15px 3em;
    }
    .page-desc {
        width: 100%;
        font-size: 1.2em;
        line-height: 1.5;
        color: #151515;
        letter-spacing: .05em;
    }
    .post-item,
    .post-item:nth-child(2n) {
        padding-right: 15px;
        padding-left: 15px;
    }
    #contact>div {
        margin-bottom: 30px;
    }
    .ms-footer .copyright {
        margin-left: auto;
    }
    .ms-footer .socials {
        display: none;
    }
}

/* Mmobile view custom css */

@media (min-width: 280px) and (max-width: 360px) {
  
    .ms-logo{
        height: 54px;
    }
    
  }
  @media (min-width: 280px) and (max-width: 767px) {
  
    .only_mobile{
        display: block!important
    }
    .only_web{
        display: none
    }
    .ms-logo img{
        height: 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 1700px) {
  .only_mobile{
      display: none
  }
}

/* our solution page header */
  /* hover header  */
  .header_hover_our_solu{
    color: #fff!important 
}
.header_hover_our_solu:hover{
color: #df1f29!important 
}
.sub-menu {
display:none;

}
.head_menu:hover{
height: 158px;
}
.head_menu:hover > .sub-menu {
display: block;
font-size: 8px;
/* text-align: center; */
color: #df1f29 ;
float: right;
width: 100%;
left: 50%;

}
.nav_mar_oursolution{
margin: 3% 15%;
}
.big_data_head_slider{
color: #fff;
/* text-align: center; */
z-index: 1;
position: absolute;
display: block;
margin: 0px auto;
left: 45%;
top: 40%;
}
/* header media query */
@media (min-width: 280px) and (max-width: 767px) {
.head_menu:hover{
    height: 106px;
}
}

/* new nav bar */
nav {
    margin: 0% 0px;
    padding: 1%;
    position: relative!important;
    z-index: 999;
    width: 100%;
    background-color: transparent;
    transition: background-color 2.4s ease-out;
  }
  /* .navbar-fixed-top.scrolled {
    background-color: #08215fb0!important;
    transition: background-color 1.4s ease-out;
    padding: 0%
  }
  .navbar-fixed-top.scrolled:hover{
    background-color: #08215ff0!important
  } */
  #logo {
    display: block;
    padding: 0 30px;
    float: left;
    font-size: 20px;
    line-height: 60px;
  }
  
  nav:after {
    content: "";
    display: table;
    clear: both;
  }
  
  nav ul {
    float: right;
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
  }
  
  nav ul li {
    margin: 0px;
    display: inline-block;
    font-size: 15px;
    /* float: left; */
    /* background-color: #254441; */
  }
  
  nav a {
    display: inline-block;
    /* padding: 0 20px; */
    color: #000;
    font-size: 20px;
    /* line-height: 60px; */
    text-decoration: none;
  }
  
  nav ul li ul li:hover { background: #000000; }
  
  /* nav a:hover { background-color: #000000; } */
  
  nav ul ul {
    display: none;
    position: absolute;
    top: 60px;
  }
  
  nav ul li:hover > ul { display: inherit; }
  
  nav ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative;
  }
  
  nav ul ul ul li {
    position: relative;
    top: -60px;
    left: 170px;
  }
  
  /* li > a:after { content: ' +'; } */
  
  li > a:only-child:after { content: ''; }
  .toggle, [id^=drop] {
    display: none;
}
  
  /* Media Queries
  --------------------------------------------- */
  
  @media all and (max-width : 768px) {
    .nav_logo_new{
        width:80%!important;
        margin-top: 1%;
        padding: 2%!important;
    }
    .atg_nav_hover:hover{
        width: 100%
    }
    .atg_nav_hover{
        display: block
    }
  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }
  
  nav { margin: 0; }
  
  .toggle + a,
   .menu { display: none; }
  
  .toggle {
    display: block;
    /* background-color: #254441; */
    padding: 0 20px;
    color: #FFF;
    font-size: 40px;
    line-height: 60px;
    text-decoration: none;
    border: none;
    float: right
  }
  .drop_down_mob_label{
      float: none!important;
      font-size: 20px;
  }
  /* .toggle:hover { background-color: #000000; } */
  
  [id^=drop]:checked + ul { 
    display: block; 
    background: #5c5c5f;
    width: 100%;

}
  
  nav ul li {
    display: block;
    width: 100%;
  }
  
  nav ul ul .toggle,
   nav ul ul a { padding: 0 40px; }
  
  nav ul ul ul a { padding: 0 80px; }
  
  nav a:hover,
   /* nav ul ul ul a { background-color: #000000; } */
  
  nav ul li ul li .toggle,
   nav ul ul a { background-color: #212121; }
  
  nav ul ul {
    float: none;
    position: static;
    color: #ffffff;
  }
  
  nav ul ul li:hover > ul,
  nav ul li:hover > ul { display: none; }
  
  nav ul ul li {
    display: block;
    width: 100%;
  }
  
  nav ul ul ul li { position: static;
  
  }
  }
  
  @media all and (max-width : 330px) {
  
  nav ul li {
    display: block;
    width: 94%;
  }
  
  }
  /* Brackets go out */
.brackets a:before, .brackets a:after
{
    position: absolute;
    opacity: 0;
    font-size: 35px;
    top: 0px;
    transition: all 0.3s;
}

.brackets a:before
{
    content: '(';
    left: 0px;
    transform: translateX(10px);
}

.brackets a:after
{
    content: ')';
    right: 0px;
    transform: translateX(-10px);
}

.brackets a:hover:before, .brackets a:hover:after
{
    opacity: 1;
    transform: translateX(0px);
}
.active_cus{
    /* color: #101f7d!important; */
    color:#0b69af!important;
    /* border-bottom: 2px solid #000; */
    /* border-radius: 12px; */
    /* background-color: #101f7d; */
    background-color: transparent!important;
    font-weight:700;
}
.nav_hover:hover{
    /* color: #000; */
    /* border-bottom: 2px solid #fff; */
    /* border-radius: 12px; */
    -o-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    transition: all .3s linear;
    outline: none;
    z-index: 10; 
}
    .nav_hoveraa:after{
        display: block;
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 0;
        height: 4px;
        /* background-color: #f35188; */
        content: "";
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }
    .logo nav_logo_new{
        display: inline-block !important;
    }
    .atg_nav_hover {
        padding: 0 20px;
        line-height: 36px!important;
        display: inline-block;
        /* line-height: 50px; */
        
    }
    .atg_nav_hover:hover{
        color: #101f7d!important;
        /* background-color: #101f7d */
        /* border-bottom: 2px solid #101f7d; */
        
    }
    .nav_logo_new{
        width: 70%
    }

    /* extra */
    .drop_nav_cust{
    background: #fff;
    width: 30%;
    left: 30%;
    top: 90%;
    height: 135px;
    
    }
    .drop_down_cust{
    color: #000;
    background-color: transparent;
    font-size: 17px
    }
    .drop_down_cust:hover{
    /* color: #000!important; */
    background-color: #000;
    border-bottom: 1px solid #000;
    width: 100%;
    }
    .navbar-fixed-top{
        position: relative;
    }
    .navbar-fixed-top.scrolled {
    background-color: #ffffff!important;
    transition: background-color 200ms linear;
    padding: 0px;
    position: fixed!important;
    border-bottom: 1px solid #d6d6d6;
    margin-top: 0px;
  }
  /* new navbar header top #101f7d*/
  /* .navbar-fixed-top.scrolled > .menu_new > ul > li a {
      color:#fff
  } */
  
  .bg_nav_clr_black{
      /* background: #101f7d;     */
      background-color: #2538b1;
      padding: 1% 3% 0% 3%;
      z-index: 12334456
  }
  .nav_logo_new img{
      width: 100%;
  }
  .navbar-fixed-top.scrolled>.nav_logo_new img {
    width: 70%;
    padding: 0%;
  }
  nav{
      padding: 0px
  }
  .clr_white_nav{
    color: #fff;
    border-right: 1px solid #fff;
    text-transform: none;
    padding: 0px 3%;
    padding: 0px 3%;
    font-size: 14px
  }
  .dis_flex_top_nav{
    display: flex;
  }
  .scoial_navbar{
    padding: 0px 2%;
    color: #fff;
    font-size: 14px
  }
  .br_social_nav{
    border-right: 1px solid #fff;
  }
.quote_btn_nav{
    margin-top: -10%;
    padding: 8%;
}
.contact_nav_btn{
    background: #101f7d;
    color: #fff;
    line-height: 30px;
    padding: 0px 24px;
}
.contact_nav_btn:hover{
    color:#fff!important;
    text-decoration: underline
}
/* mega menu */
.menu_new {
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}
.menu_new div,
.menu_new ul {
    margin: 0 !important;
    padding: 0 !important;
    width: auto !important;
}

.menu_new-container {
  margin: 0 auto;
  background: #fff;
  height: 65px;
  position: absolute;
}
.menu_new a.logo{
    width: 70%;
    padding: 10px !important;
    margin: 0 !important; 
}
.menu_new img {
    max-width: 100%;
}
.menu_new-mobile {
  display: none;
  padding: 20px;
}
.menu_new-mobile:after {
  content: "\f0c9";
  font-family: "FontAwesome";
  font-size: 2.5rem;
  padding: 0;
  float: right;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.menu_new-dropdown-icon:before {
  content: "\f067";
  font-family: "FontAwesome";
  display: none;
  cursor: pointer;
  float: right;
  padding: 1.5em 2em;
  background: #fff;
  color: #333;
}
.menu_new > ul {
  margin: 1% !important;
  width: 100%;
  list-style: none;
  padding: 0;
  /* position: relative; */
  /* IF .menu_new position=relative -> ul = container width, ELSE ul = 100% width */
  box-sizing: border-box;
      clear: right;
      /* display: flex; */
}
.menu_new > ul:before,
.menu_new > ul:after {
  content: "";
  display: table;
}
.menu_new > ul:after {
  clear: both;
}
.menu_new > ul > li {
  float: left;
  /* background: #ffffff; */
  padding: 0;
  margin: 0;
}
.menu_new > ul > li a {
    text-decoration: none;
    padding: 5px 10px;
    display: inline-block;
    color: #000; 
}
/* .menu_new > ul > li:hover a {
    color: #ffffff; 
  } */
.menu_new > ul > li:hover {
  /* background: #f0f0f0; */
}
.menu_new > ul > li > ul {
    display: none; /* Hide submenu initially */
    position: absolute;
    width: 80% !important;
    background-color: #0b69af;
    padding: 2% !important;
    z-index: 99;
    left: 10%;
    min-width: 1140px;
    border-top: 5px inset #0b69af;
    border-bottom: 5px inset #0b69af;
}
.menu_new > ul > li:hover > ul {
    display: block; /* Show submenu on hover */
  }
  .menu_new > ul > li > ul > li a {
    color: #fff; /* Submenu link color */
  }
  .menu_new > ul > li > ul > li a:hover {
    color: #ffffff; /* Submenu hover color */
    text-decoration: underline;
  }
.menu_new > ul > li > ul:before,
.menu_new > ul > li > ul:after {
  content: "";
  display: table;
}
.menu_new > ul > li > ul:after {
  clear: both;
}
.menu_new > ul > li > ul > li {
  margin: 0;
  padding-bottom: 0;
  list-style: none;
  width: 25%;
  background: none;
  float: left;
}
.menu_new > ul > li > ul > li a {
    color: #f15b29;
    width: auto; 
    display: inline-block; 
    border-bottom: 1px solid #ccc;
}
.menu_new > ul > li > ul > li a:hover{
    color: #ffffff;
    text-decoration: underline;
}
.menu_new > ul > li > ul > li > ul {
  display: block;
  padding: 0;
  margin: 10px 0 0;
  list-style: none;
  box-sizing: border-box;
}
.menu_new > ul > li > ul > li > ul:before,
.menu_new > ul > li > ul > li > ul:after {
  content: "";
  display: table;
}
.menu_new > ul > li > ul > li > ul:after {
  clear: both;
}
.menu_new > ul > li > ul > li > ul > li {
  float: left;
  width: 100%;
  padding: 10px 0;
  margin: 0;
  font-size: .8em;
}
.menu_new > ul > li > ul > li > ul > li a {
  border: 0;    
  font-size: 1.2rem !important;
  color: #fff;
}
.menu_new > ul > li > ul.normal-sub {
  width: 300px;
  left: auto;
  padding: 10px 20px;
}
.menu_new > ul > li > ul.normal-sub > li {
  width: 100%;
}
.menu_new > ul > li > ul.normal-sub > li a {
  border: 0;
  padding:  0;
}
.menu_new > ul > li > ul > li > ul > li a:before{
    content: '\f0da';
    font-family: 'FontAwesome';
    margin-right: 5px;
    margin-left: 5px;
    color: #fa4112;
}
.drophead_line{
    font-weight: 700;
    color:#fff!important
}
.drophead_line:hover{
    text-decoration: none!important;
}
.header_btn_contact{
    padding: 10px 22px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #000;
    /* background-color: #FF9800; */
    background-color: #fff;
    border: none;
    border-radius: 2px;
    margin-top: 14px;
}
.navbar-fixed-top.scrolled > .menu_new > ul > a>.header_btn_contact{
    background-color: #101f7d;
    color:#fff
}
.scolled_logo{
    display: none;
}
.navbar-fixed-top.scrolled > .menu_new > a>.scolled_logo{
    display: block!important
}
.navbar-fixed-top.scrolled > .menu_new > a>.normal_logo{
display: none;
}
.normal_logo{
    width: 65%!important;
}
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (max-width: 1024px) {
  .menu_new-container {
    width: 100%;
  }
  .menu_new-container .menu_new{
	display:inline-block;
   }
  .menu_new-mobile {
    display: block;    
    float: right;    
    padding: 20px 20px 0;
  }
  .menu_new-dropdown-icon:before {
    display: block;
  }
  .menu_new > ul {
    display: none;
    width:100%;
  }
  .menu_new > ul > li {
    width: 100%;
    float: none;
    display: block;
  }
  .menu_new > ul > li a {
    padding: 0px 20px;
    width: 100%;
    display: block;
  }
  .menu_new > ul > li > ul {
    position: relative;    
    padding: 0 40px;
  }
  .menu_new > ul > li > ul.normal-sub {
    width: 100%;
  }
  .menu_new > ul > li > ul > li {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  .menu_new > ul > li > ul > li:first-child {
    margin: 0;
  }
  .menu_new > ul > li > ul > li > ul {
    position: relative;
  }
  .menu_new > ul > li > ul > li > ul > li {
    float: none;
  }
  .menu_new .show-on-mobile {
    display: block;
    background: #ccc;
  }
  .atg_nav_hover{
    line-height: 43px;
  }
  .nav_logo_new img{
      width: 100%;
  }
  .dis_inline{
      display: inline;
  }
  .clr_white_nav{
    padding: 0px 6%;
    border:none;
    font-size: 18px
  }
  .menu_new a.logo{
    padding: 0;
   
    float: none;
    line-height: 0;
  }
  .menu_new > ul > li > ul{
    width: auto;
    left: 0;
    min-width: 100%;
    height: 400px;
    overflow: scroll;
  } 
  .wid_aus_webmob_nav{
      width: 100%!important;
  }
  .mobile_menu_clickingbtn{
      display: block;
  }
  .hide_toggle_menu{
      display: none;
  }
}
.mobile_menu_clickingbtn{
    display: none;
}
@media (min-width: 1025px) and (max-width: 1280px) {
    .menu_new > ul > li a{
        padding: 0 12px;
    }
  }
.link_austra{
    border-bottom: none!important;
    color: #101f7d!important;
    font-weight: 800;
}
@media (min-width: 767px) and (max-width: 1028px) {
.nav_logo_new img{
    width: 45%;
    margin-top: -1%;
}
}
.wid_aus_webmob_nav{
    width: 40%;
}

@media (min-width: 280px) and (max-width: 360px) {
    .nav_logo_new{
        width: 77%!important;
    }
}
@media (min-width: 990px) and (max-width: 1024px) {
    .nav_logo_new{
        width: 77%!important;
    }
    .nav_logo_new img{
        width: 57%;
        margin-top: 0px;
    }
}


/* custom font */
/* .fa{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
} */

/* Noor */
.ctm_top_nav{
    background-color: #f1f1f1;
    padding: 7px 7px 7px 25px;
}
.ctm_top_nav.scrolled{
    display: none;
}
.ctm_icons{
    color: #38479a;
    font-size: 14px;
}  

@media (min-width: 1220px) and (max-width: 1230px) {
    .menu_new>ul>li>ul {
        left: 1%;
    }
}

@media (min-width: 1700px) and (max-width: 1920px) {
    .menu_new>ul>li>ul {
        width: 85% !important;
    }

    .menu_new > ul > li > ul > li > ul > li a {
        font-size: 1.6rem !important;
    }
    
    .drophead_line {
        font-size: 1.8rem;
    }
}

@media (min-width: 1930px) and (max-width: 2350px) {
    .menu_new>ul>li>ul {
        width: 85% !important;
    }

    .menu_new > ul > li > ul > li > ul > li a {
        font-size: 1.8rem !important;
    }

    .drophead_line {
        font-size: 1.9rem;
    }
}