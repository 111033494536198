/* for app.css */
a:focus, a:hover{
    text-decoration: none
}
h1 {
    font-size: 30px!important;
}
@media (min-width: 280px) and (max-width: 767px) {
  
    h1 {
        font-size:30px!important;
    }
    p{
      text-align: left!important;
    }
  }
  .not_found_backto{
    position: absolute;
    margin: -7%;
    font-size: 21px;
    color: #0707c3;
    text-decoration: underline
  }

  /* font for all pages */
  p,h1,h2,h3,h4,h5,h6,div,li,ul,span,section,select,option,a{
      /* font-family: 'Montserrat', sans-serif!important */
      font-family: "Muli", Open Sans, sans-serif!important;
  }
  /* blog page css */
  .blog_header{
    /* position: absolute;
    margin: -7%;
    font-size: 21px;
    color: #0707c3;
    text-decoration: underline */
    background-image: url('./img_new/blog_header.jpg');
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    /*box-shadow: inset 0 0 0 2000px rgba(4, 41, 72, 0.72);*/
  }
  .head_blog{
    color: #fff;
    padding-top: 166px;
    text-align: center;
    text-transform: none;
  }
  .blog_txth1{
    color: #919191;
    text-transform: uppercase;
    font-family: 'Montserrat',sans-serif;
    font-size: 40px;
    padding: 3% 0% 3% 0%; 
    font-weight: 150;
    /*word-break: break-all;*/
    /*line-height: 56px;
      font-size: 30px!important;
      padding: 3% 0% 3% 0%; */
  }
  .blog_ptag{
      padding: 0% 0% 2% 0%;
      height: 120px;
      overflow: auto;
  }
  .blog_height{
      height: 300px;
      padding: 2%;
  }
  .pad_sapn_dat_blog{
    float: right!important;
    padding: 1% 0px;
    color:#949090;
    font-size: 15px;
  }
  /* ::-webkit-scrollbar {
    margin-left: 30px;
      float: left;
      height: 300px;
      width: 15px;
      background: #F5F5F5;
      overflow-y: scroll;
      margin-bottom: 25px;
      border-radius:20px
  } */
    .blog_post_img{
        height: 260px;
        border-radius: 2px
    }

  .bor_height_blog{
      border:1px solid #d4c8c8;
      /* height: 260px; */
      border-radius: 2px
  }


#style-3::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
    background-color: #383737;
    border-radius:40px
}

@media (min-width: 280px) and (max-width: 1024px) {
  
  .blog_height{
    height: auto;
  }
  .bor_height_blog{
    height: auto;
  }
  /* .row {
    margin-right: 0px;
    margin-left: 0px;
} */
}

/* landing page webdesign page  */
.bg_landing_web{
  /* background-image: url('./img_new/web_landing.jpg'); */
  height: 660px;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(4, 41, 72, 0.72)
}
/* form */

#contact {
  background: #F9F9F9;
  padding: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  width: 65%;
  display: block;
  margin: 100px auto;
  border-radius: 4px;
}
#contact fieldset { 
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #4CAF50;
  color: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

#contact button[type="submit"]:hover {
  background: #43A047;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}
.cen_bor_txt_lan{
  padding: 25px 0px;
  text-align: center;
  /* border-bottom: 3px solid #cdd0d1; */
  -webkit-appearance: none;
  
}
.sec2headtag{
    text-transform: uppercase;
    font-size: 24px;
    padding: 0% 0% 1% 0%;
    font-weight: bold;
    
}
.third_bor_txt_lan{
  padding: 25px 0px;
  text-align: center;
  /* border-bottom: 3px solid #cdd0d1; */
  -webkit-appearance: none;
  background: #eff0f1;
}
.ptag_managebox{
  position: relative;
  float: left;
  display: block;
  overflow: hidden;
  border-right: 1px solid #423d3d7a;
  border-bottom: 1px solid transparent;
  padding: 3%;
  background: transparent;
}

.h1_tag_index{
  position: absolute;
  color: transparent;
  visibility: hidden;
}

/* for our service more link  */
.bg_web_service_more{
  /* background-image: url('./img_new/webdesign_more.png'); */
  height: 500px;
  background-size: cover;

  margin-top: -1%;
  /* box-shadow: inset 0 0 0 2000px rgba(4, 41, 72, 0.72) */
}
.mrg_head_webservice{
  margin-top: 28%;
    color: #fff;
}
.color_white{
  color:#fff;
  padding: 3% 9%;
  text-align: justify
}
.ptag_ser_2sec_more{
  padding: 1% 25%;
  text-align: justify
}
.mrg_top_mr_wesr{
  margin-top: 3%
}
.bck_mr_wese{
  background-color: #ccc;
  padding: 1% 5%
}
.bg_boxsemor{
  background-color: #fff;
  padding: 4%;
  box-shadow: 5px 4px 8px 5px #98949459;
  height: 165px;
  
}
.mr_se_web_icon{
  font-size: 50px;
  color: #002bb0;
  text-align: center;
  display: block;
}
.txtmrwebinner{
  text-align: center;
}
.ptgwebmorserinn{
text-align: justify;
height: 70px;
overflow: auto;
}
/* landing 1-08-2019 */
.custom_contant_rite{
  background: #25252f;
  color: white;
  float: right;
  text-align: right;
  padding: 8% 3%;
}

.inside_rite_head{
  font-size: 80px;
  letter-spacing: 0.04em;
  margin: 0 0 40px;
  line-height: 46px;
}
.inside_rite_head h2{
  color: #fff
}
.inside_rite_para p{
  color: #fff
}
.inside_rite_para{
  font-size: 16px;
  letter-spacing: 0.04em;
  margin: 0 0 40px;
  line-height: 28px;
}

.custom_contant_left{
  color: black;
  float: left;
  padding: 8% 0%;
}

.inside_left_head{
  font-size: 80px;
  letter-spacing: 0.04em;
  margin: 0 0 40px;
  line-height: 46px;
}

.inside_left_para{
  font-size: 16px;
  letter-spacing: 0.04em;
  margin: 0 0 40px;
  line-height: 28px;
}

/* ibra */
.btn-theme {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  margin-right: 15px;
}
.btn-min-block {
  min-width: 170px;
  line-height: 26px;
}
.custom_btn_ibr {
  color: rgb(0, 0, 0);
  background-color: transparent;
  border: 2px solid #000;
  margin-right: 15px;
}
.custom_btn_ibr:hover {
  color: #fff!important;
  background-color: #25252f!important;
  border-color: #fff!important;
}
.btn-theme:hover {
  color: #25252f;
  background-color: #ffffff;
  border-color: #fff;
}    

/* slider_landing */
.web_apps_landing{
  width: 100%!important;
  height:auto;
  margin: 2% 0px;
}
.bg_web_land_slider{
  background-color: #cccccc2b;
}
.blog_read_more{
  float: left!important;
  padding: 1% 0;
  color: #ff9905;
  text-decoration: underline;
  font-size: 15px;
}
/* accordian */
/* .Collapsible{
  font-size: 20px;
  color:#000;
  padding: 1% 2%;
  border: 1px solid #ccc;
} */
.atag_center{
  text-align: center;
  font-size: 18px;
  padding: 1% 0;
}
.atag_accordian{
  color:#101f7d;
}
.pad_accoridian{
  padding:0 2% 2%
}
.panel-accordion{
  border:1px solid #ccc
}
.accordion-head{
  background-color: #0b69af!important;
  border-bottom: 1px solid #fff!important;
  font-size: 16px;
    font-weight: 500;
    padding: 13px!important;
}


.accordion-head:after{

    font-family: FontAwesome;
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;

}


  /* skills */
  .pad_ul_skills{
    padding: 0 5%!important
  }
  .pad_ul_skills>li{
    padding: 1%;
    
  }
  .liststyletype_li_career{
    list-style-type: disc;
  }
  .career_header{
    /* background-image: url('./img_new/career_bg.jpg');
    height: 400px;
    background-size: cover;
    margin-top: -1%; */
  
    background-image: url(./img_new/career_bg.jpg);
    height: 450px;
    background-size: cover;
    margin-top: -1%;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    min-height: 90vh;
  }
  .panel-accordion >p > b {
    padding: 0 3%
  }
  .ptag_pad_carrer{
    padding: 0 3%
  }

  
.flt_right_career{
  float: right;
}
@media (min-width: 280px) and (max-width: 767px) {
  .career_header{
    width: 100%;
    margin-top: 20%;
    height: 25vh;
    min-height: 25vh;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
}
.pad_accoridian{
  padding: 0 4% 4%;
}
.atag_center{
  font-size: 15px;
  padding: 2%;
}
.about_ser_new_tags{
  font-size: 24px!important;
}
}

.blog_pfl{
  height: 105px!important;
  font-family: 'Open Sans',sans-serif;
  color: #9c9c9c!important;
  word-break: break-all;
  /*font-size: 18px;*/
}
@media (min-width: 768px) and (max-width: 1024px) {
  .career_header{
    margin-top: 4%;
    min-height: 33vh;  }
 
  
}