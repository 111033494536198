.bg_color_change{
  height: 10%;
}
/* .color-violet {
    background-color: #7A4EAB!important;
  }
  .color-indigo {
    background-color: #ffffff!important;
  }
  .color-blue {
    background-color: #2F8FED!important;
  }
  .color-green {
    background-color: #4DCF42!important;
  }
  .color-yellow {
    background-color: #ec7263!important;
  }
  .color-orange {
    background-color: #F19031!important;
  }
  .color-red {
    background-color: #ff6699!important;
  }
  .color-gray{
      background-color: #14b09b; 
  } */

/* all videos */
 /* extra videos */
 .laptop-wrapper_new_IE {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.laptop-wrapper_new_IE video {
  box-sizing: border-box;
  /* background: url(./img_new/laptop_new.png) center center no-repeat; */
  background-size: contain;
  padding: 11.9% 15.5% 14.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.laptop_with_mobile-wrapper_new_IE{
  box-sizing: border-box;
  /* background: url('./img_new/web_design_img.png') center center no-repeat; */
  background-size: contain;
  padding: 21.9% 17.5% 17.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
/* 2nd example :  BACKGROUND IMAGE */
.desktop-wrapper_new_IE {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.desktop-wrapper_new_IE video {
  box-sizing: border-box;
  /* background: url(./img_new/computer_desktop.png) center center no-repeat; */
  background-size: contain;
  padding: 1.4% 13.8% 26.6%;/* 11.9% 15.5% 14.8% */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

/* 3rd example :  BACKGROUND IMAGE */
.tablet-wrapper_new_IE {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.tablet-wrapper_new_IE video {
  box-sizing: border-box;
  /* background: url(./img_new/tablet_new.png) center center no-repeat; */
  background-size: contain;
  padding: 13.9% 13% 13.5% 9.8%;/* 11.9% 15.5% 14.8% */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
/* 4th mobile css */
.mobile-wrapper_new_IE {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.mobile-wrapper_new_IE img {
  box-sizing: border-box;
  /* background: url(./img_new/mobile_new.png) center center no-repeat; */
  background-size: contain;
  padding:6.1% 33.9% 7%;/* 11.9% 15.5% 14.8% */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}




.mrg_final_bot_videos{
  padding-bottom: 5%
}
.mgr_new_all_videos{
  margin-bottom: 15%;
}
.mgr_new_all_videos_new_webdesign{
  margin-bottom: 5%;
}
.mr_first_mobile_top{
  margin-top: 5%;
}
/* .first_mobile_txt_left{
  left:5%
} */
.change_order_mt_webdesign{
  margin-top: 1%;
}
/* media query */
@media (min-width: 280px) and (max-width: 767px) {
  .desktop-wrapper_new_IE video{
    padding: 0.4% 11.8% 26.6%!important;
    top: 9%!important;
  }
  .tablet-wrapper_new_IE video{
    padding: 14.9% 13% 16.5% 9.8%!important;
  }
  .laptop-wrapper_new_IE video{
    padding: 14.9% 15.5% 14.8%!important;
  }
  .mrg_web_mobile_design_top{
    margin-top: 60%;
  }
  .mrg_web_design_10{
    margin-top: 12%;
  }
  .button_port_color_more{
    width: 60%!important;
  }
  .port_laptop_txt_color{
    font-size: 18px
  }
  .mobile-wrapper_new_IE img{
    padding: 7.1% 31.9% 7%!important;
  }
  .port_mobile_txt_color{
    margin-bottom: 0;
  }
  .dis_none_mobile_svg_seo{
    display: none
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
.mgrt_color_third_new1{
  margin-top: 7%!important;
}
.mrg_tap_top_res{
  margin-top: 43%;
}
.dis_none_mobile_svg_seo{
  display: none
}
.mgr_new_all_videos{
  margin-bottom: 0;
  margin-top: 2%;
}
.tab_seo_css_new{
  width: 60%;
  display: block;
  margin: 0px auto;
}
}

/* for digital marketing css */
/* seo page animations */
.seo_img{
  width: 66%;
  display: block;
  margin: 0px auto;
  position: absolute;
  top: 15%;
  left: 19%;


}
.wrapper_seo_page{
  text-align:center;
}

.svg_seo_page{
  margin: 0 auto;
  width:100%;
  height:auto;
}


@media (min-width: 280px) and (max-width: 767px) {
  
  .web_view_none_seo{
    display:none
  }
  .mobile_view_none_seo{
    display: block!important
  }
}
@media (min-width: 768px) {
  .mobile_view_none_seo{
    display: none
  }
  
}
/* newly added */
.port_laptop_txt_color{
  /* font-size: calc(28px + 16 * (100vw - 992px) / 1508)!important; */
  font-size: 18px!important;
  text-transform: none
}