
  strong.color-1 {
    color: #2962ed;
    font-style: italic;
  }
  img {
    width: 100%;
    height: auto;
  }
  
  span, a, a:hover {
    display: inline-block;
    text-decoration: none;
    color: inherit;
  }
  
  a.n-cursor {
    cursor: pointer;
  }
  
  .section-head {
    margin-bottom: 10px;
  }
  .section-head h4 {
    padding: 0;
    line-height: 1;
    letter-spacing: -2px;
    font-size: 38px;
    font-weight: 800;
    color: #fff;
    margin: 0;
  }
  .section-head h3 {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 5px;
  }
  .section-title {
    padding: 0;
    margin: 0;
    margin-bottom: 40px;
  }
  .section-title span {
    text-transform: uppercase;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .section-title h2 {
    font-family: 'Poppins' , sans-serif;
    font-weight: 800;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .section-title.style-2 h2 {
    font-size: 28px;
  }
  .section-title.bot-80 {
    margin-bottom: 80px;
  }
  .bg-gray {
    background: #f7f7f7;
  }
  .bg-gray .owl-theme .owl-dots .owl-dot span {
    background: #777;
  }
  
  /* Background overlay */
  
  .bg-overlay {
    position: relative;
  }
  
  .bg-overlay:before {
    background-color: rgba(15, 39, 101, 0.85);
    z-index: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  

  
  .bg-parallax:before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.9);
    position: absolute;
  }
  
  .bg-canvas {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .video_bg_header_about_inner{
    background: #2154cf
  }
  .bubble-canvas {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .rain-canvas {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .solid-bg {
    background: #f7f9fd;
  }
  
  .o-hidden {
    overflow: hidden;
  }
  
  .position-re {
    position: relative;
  }
  
  .full-width {
    width: 100% !important;
  }
  
  .bg-img {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  
  #reactive-bg-canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 199999;
  }
  
  .section-padding {
    padding: 100px 0;
  }
  
  .valign {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
  }
  
  .cd-headline {
    font-size: 3rem;
    line-height: 1.2;
  }
  .bot-50 {
    margin-bottom: 50px;
  }
  @media only screen and (min-width: 768px) {
    .cd-headline {
      font-size: 4.4rem;
      font-weight: 300;
    }
    
  }
  @media only screen and (min-width: 768px) {
  .mob_fn{
    font-size: 12px
  }
}
  @media only screen and (min-width: 1170px) {
    .cd-headline {
      font-size: 6rem;
    }
  }
  .cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
  }
  
  .cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
    
  }
  
  .cd-words-wrapper b.is-visible {
    position: relative;
  }
  
  .no-js .cd-words-wrapper b {
    opacity: 0;
  }
  
  .no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
  }
  
  /* xclip */
  .cd-headline.clip span {
    display: inline-block;
    padding: .2em 0;
  }
  
  .cd-headline.clip .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
  }
  
  .cd-headline.clip .cd-words-wrapper::after {
    /* line */
    content: '';
    position: absolute;
    top: 10%;
    right: 0;
    width: 2px;
    height: 70%;
    /* background-color: #aebcb9; */
  }
  
  .cd-headline.clip b {
    opacity: 0;
  }
  
  .cd-headline b.is-visible {
    opacity: 1;
    font-weight: 900;
  }
  
  .owl-theme .owl-dots .owl-dot span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #e0e0e0;
  }
  
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    /* background: url(../img/dot-actvie.png); */
    width: 16px;
    height: 16px;
  }
  
  /* ----------------------------------------------------------------
       [ End Basic ]
  -----------------------------------------------------------------*/
  
  
  /* ----------------------------------------------------------------
       [ 02 Start top-bar ]
  -----------------------------------------------------------------*/
  .top-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: transparent;
    z-index: 199;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgba(255,255,255,.15);
  }
  .top-bar .topbar-info-list {
    margin: 0;
    padding: 0;
  }
  .top-bar .topbar-info-list ul {
    list-style-type: none;
  }
  .top-bar .topbar-info-list li {
    display: inline-block;
    color: #fff;
    font-weight: 300;
    padding-right: 20px;
  }
  
  /*  topbar social */
  
  .top-bar .topbar-social-list {
    margin: 0;
    padding: 0;
  }
  .top-bar .topbar-social-list ul {
    list-style-type: none;
  }
  .top-bar .topbar-social-list li {
    display: inline-block;
    color: #fff;
    font-weight: 300;
    padding-right: 20px;
  }
  .topbar-scroll {
    background: #fff;
    border-bottom: 1px solid rgba(12, 12, 12, 0.04);
    padding: 0;
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  /* ----------------------------------------------------------------
       [ 02 Start Navbar ]
  -----------------------------------------------------------------*/
  
  .navbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: transparent;
    z-index: 199;
    min-height: 80px;
    border-bottom: 1px solid rgba(255,255,255,.15);
  }
  .navbar.top-40 {
    top: 40px;
  }
  .navbar.top-0 {
    top: -100px;
  }
  .navbar.bottom-bar {
    top: auto;
    bottom: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  .navbar.top-bar {
    top: 0;
    bottom: auto;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  .navbar .icon-bar {
    color: #fff;
  }
  .navbar .navbar-nav .nav-link {
    font-size: 14px;
    font-weight: 600;
    color: #eee;
    margin: 15px 5px;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  .navbar .navbar-nav.color-1 .nav-link {
    color: #000;
  }
  .navbar .navbar-nav .nav-link.padd-space {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar .navbar-nav .nav-link.left-10 {
    margin-right: -50px;
  }
  .navbar .navbar-nav .nav-link.border-space {
    border-right: 1px solid rgba(255,255,255,.15);
  }
  .navbar .navbar-nav .nav-link:hover {
    color: #2154cf;
  }
  .navbar .navbar-nav .active {
    color: #2154cf !important;
  }
  .padd-left {
    padding-left: 200px;
  }
  .nav-scroll {
    background: #fff;
    border-bottom: 1px solid rgba(12, 12, 12, 0.04);
    padding: 0;
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  .nav-scroll .icon-bar {
    color: #222;
  }
  .nav-scroll .navbar-nav .nav-link {
    color: #222;
  }
  .nav-scroll .navbar-nav .logo {
    padding: 15px 0;
    color: #111;
  }
  
  .logo {
    padding: 10px 0;
    width: 38%;
  }
  .logo.center-logo {
    padding: 10px 0;
    width: 120px;
    position: absolute;
    left: 50%;
    margin-left: -55px;
  }
  /* ----------------------------------------------------------------
       [ End Navbar ]
  -----------------------------------------------------------------*/
  /* ----------------------------------------------------------------
       [ 03 Start Header_about_inner ]
  -----------------------------------------------------------------*/
  .header_about_inner {
    min-height: 90vh;
    overflow: hidden;
    position: relative;
    margin-bottom:1%;
    margin-top: 3%;
  }
  .header_about_inner .caption .o-hidden {
    display: inline-block;
  }
  .header_about_inner .caption h3 {
    font-weight: 200;
    letter-spacing: 3px;
    -webkit-animation-delay: .2s;
            animation-delay: .2s;
  }
  .header_about_inner .caption h1 {
    margin: 10px 0;
    font-size: 70px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
    margin-top: 5%;
  }
  .header_about_inner .caption h1 b {
    color: #2154cf;
  }
  .header_about_inner .caption p {
    font-size: 16px;
    color: #eee;
    word-spacing: 2px;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .header_about_inner .caption .butn {
    padding: 12px 30px;
    border: 1px solid #eee;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 0px;
    position: relative;
    margin: 30px 9px 0;
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
  }
  .header_about_inner .caption .butn:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 1;
    border-radius: 0px;
    background: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .header_about_inner .caption .butn:hover {
    border-color: #fff;
  }
  .header_about_inner .caption .butn:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  .header_about_inner .caption .butn:hover span {
    color: #1c1c1c;
  }
  .header_about_inner .caption .butn span {
    position: relative;
    z-index: 2;
  }
  .header_about_inner .caption .butn-bg {
    background: #2154cf;
    border-color: #2154cf;
  }
  .header_about_inner .arrow {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 13px;
    z-index: 8;
  }
  .header_about_inner .arrow i {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .header_about_inner .arrow i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    z-index: -1;
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  .header_about_inner .arrow i:hover:after {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  
  .slider .arrow, .slider-fade .arrow {
    display: none !important;
  }
  .slider .owl-item, .slider-fade .owl-item {
    height: 100vh;
    position: relative;
  }
  .slider .item, .slider-fade .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
  }
  .slider .item .caption, .slider-fade .item .caption {
    z-index: 9;
  }
  .slider .owl-theme .owl-dots, .slider-fade .owl-theme .owl-dots {
    position: absolute;
    bottom: 5vh;
    width: 100%;
  }
  
  .creative {
    min-height: 100vh;
  }
  .creative .svg {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  .creative .svg svg {
    fill: #fff;
    width: 101%;
  }
  
  .bg-vid {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
  
  #particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  
  /* ----------------------------------------------------------------
       [ End Header_about_inner ]
  -----------------------------------------------------------------*/
  
  /* ----------------------------------------------------------------
       [ 04 Start Hero ]
  -----------------------------------------------------------------*/
  .hero .extra-text {
    font-weight: 200;
    margin-bottom: 25px;
  }
  .hero .extra-text span {
    font-weight: 700;
  }
  .hero .item {
    background: #fff;
    text-align: center;
    padding: 30px 15px;
    -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
            box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
    border-radius: 5px;
    margin-bottom: 30px;
  }
  .hero .item .icon {
    font-size: 50px;
    margin-bottom: 15px;
    color: #2154cf;
  }
  .hero .item h6 {
    margin-bottom: 15px;
  }
  .hero .mission p {
    margin-bottom: 30px;
  }
  .hero .mission i {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #2154cf;
    border-radius: 50%;
    color: #fff;
    font-size: 25px;
  }
  .hero .mission .small-text {
    margin-left: 10px;
    font-size: 13px;
    color: #666;
  }
  .hero .skills {
    padding-top: 30px;
  }
  .hero .skills .prog-item {
    margin-bottom: 25px;
  }
  .hero .skills .prog-item:last-child {
    margin-bottom: 0;
  }
  .hero .skills .prog-item p {
    font-weight: 300;
    font-family: 'Poppins' , sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .hero .skills .prog-item .skills-progress {
    width: 100%;
    height: 3px;
    background: #eee;
    border-radius: 5px;
    position: relative;
  }
  .hero .skills .prog-item .skills-progress span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #2154cf;
    width: 10%;
    border-radius: 10px;
    -webkit-transition: all 1s;
    transition: all 1s;
  }
  .hero .skills .prog-item .skills-progress span:after {
    content: attr(data-value);
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 14px;
    color: #696969;
  }
  .hero .skills.style-1 p {
    color: #d3d3d3;
  }
  .hero .skills.style-1 .prog-item .skills-progress span:after {
    color: #fff;
  }
  /* ----------------------------------------------------------------
       [ End Hero ]
  -----------------------------------------------------------------*/
  /* ----------------------------------------------------------------
       [ 05 Start Quote ]
  -----------------------------------------------------------------*/
  .quote {
    background-attachment: fixed;
  }
  .quote .quote-text {
    padding: 80px 15px;
  }
  .quote .icon {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .quote p {
    font-size: 17px;
    font-style: italic;
  }
  .quote h5 {
    color: #2154cf;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0 10px;
  }
  .quote h6 {
    font-size: 12px;
    color: #ccc;
  }
  
  /* ----------------------------------------------------------------
       [ End Quote ]
  -----------------------------------------------------------------*/
  
  
  /* ----------------------------------------------------------------
       [ 06 Start Portfolio ]
  -----------------------------------------------------------------*/
  
  .portfolio {
    overflow: hidden;
  }
  .portfolio .filtering {
    border: 1px solid #e4e4e4;
    border-radius: 30px;
    margin-left: 10px;
  }
  .portfolio .filtering span {
    color: #333;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    background: #fff;
    padding: 12px 40px;
    border-radius: 30px;
  }
  .portfolio .filtering span:last-child {
    margin: 0;
  }
  .portfolio .filtering .active {
    background: #2154cf;
    color: #fff;
    box-shadow: 2px 20px 45px 5px rgba(0,0,0,.2)
  }
  .portfolio .items {
    margin-top: 30px;
  }
  .portfolio .item-img {
    position: relative;
    overflow: hidden;
  }
  .portfolio .item-img:hover .item-img-overlay {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .portfolio .item-img:hover h6 {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .portfolio .item-img h6 {
    font-family: 'Poppins' , sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    position: relative;
    margin-bottom: 5px;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  .portfolio .item-img p {
    color: #fff;
  }
  .portfolio .item-img-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: rgba(33, 84, 207, 0.80);
    color: #35424C;
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
    z-index: 2;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  .portfolio .item-img-overlay a {
    font-size: 30px;
    color: #fff;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
  
  /* ----------------------------------------------------------------
       [ End Portfolio ]
  -----------------------------------------------------------------*/
  /* ----------------------------------------------------------------
       [ 07 Start Numbers ]
  -----------------------------------------------------------------*/
  .numbers {
    background-size: cover;
    background-position: center;
  }
  .numbers .item .icon {
    font-size: 45px;
    margin-bottom: 10px;
    color: #ccc;
  }
  .numbers .item h2 {
    font-family: 'Poppins' , sans-serif;
    font-weight: 800;
    font-size: 50px;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .numbers .item p {
    font-family: 'Poppins' , sans-serif;
    font-weight: 800;
    font-size: 50px;
    color: #fff;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .numbers .item h6 {
    font-size: 16px;
    font-family: 'Poppins' , sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    color: #ccc;
  }
  
  /* ----------------------------------------------------------------
       [ End Numbers ]
  -----------------------------------------------------------------*/
  
  
  /* ----------------------------------------------------------------
       [ 02 Start about ]
  -----------------------------------------------------------------*/
  
  /* .our-about {
    padding: 0;
    margin: 0;
    position: relative;
  }
  .our-aboutTxt {
    padding: 0;
    margin: 0;
  }
  .our-aboutTxt h5 {
    font-family: 'Raleway' , sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 20px;
  }
  .our-aboutTxt h2 {
    font-family: 'Poppins' , sans-serif;
    font-size: 40px;
    letter-spacing: -2px;
    font-weight: 700;
    line-height: 40px;
    color: #393939;
    margin-bottom: 20px;
  }
  .our-aboutTxt p {
    font-family: 'Poppins' , sans-serif;
    font-size: 14px;
    color: #777;
  }
  .our-aboutTxt a {
    padding: 15px 50px 15px 30px;
    border-radius: 30px;
    background: #2154cf;
    color: #fff;
    margin-top: 40px;
    position: relative;
  }
  .our-aboutTxt a:hover {
    background: #1a1a1a;
  }
  .btn-play {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 5;
    margin-top: 0;
    margin-left: -40px;
  }
   .btn-play i {
    background: #222;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 100%;
   }
   .btn-anim .btn-play:after,.btn-anim .btn-play:before {
       content: '';
       border: 1px solid #222;
       width: 180%;
       height: 180%;
       -webkit-border-radius: 100px;
       border-radius: 100px;
       position: absolute;
       left: -15px;
       top: -15px;
       opacity: 1;
       -webkit-animation: 1s videomodule-anim linear infinite;
       animation: 1s videomodule-anim linear infinite;
  }
   .btn-anim .btn-play:before {
       -webkit-animation-delay: .5s;
       animation-delay: .5s;
  }
   @-webkit-keyframes videomodule-anim {
       0% {
           -webkit-transform: scale(0.68);
           transform: scale(0.68);
      }
       100% {
           -webkit-transform: scale(1.2);
           transform: scale(1.2);
           opacity: 0;
      }
  }
   @keyframes videomodule-anim {
       0% {
           -webkit-transform: scale(0.68);
           transform: scale(0.68);
      }
       100% {
           -webkit-transform: scale(1.2);
           transform: scale(1.2);
           opacity: 0;
      }
  } */
  /* ----------------------------------------------------------------
       [ 08 Start services ]
  -----------------------------------------------------------------*/
  .services .item {
    padding: 30px 15px;
    -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
            box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
    border-radius: 5px;
    margin-bottom: 30px;
  }
  .services .item .icon {
    font-size: 30px;
    margin-bottom: 15px;
    color: #2154cf;
  }
  .services .item h6 {
    margin-bottom: 15px;
  }
  
  /* ----------------------------------------------------------------
       [ End Services ]
  -----------------------------------------------------------------*/
  /* ----------------------------------------------------------------
       [ 09 Start Services Tabs ]
  -----------------------------------------------------------------*/
  .serv-tabs {
    background-attachment: fixed;
  }
  .content {
    margin-top: 40px;
  }
  .serv-tabs .content .tab-item {
    display: none;
    position: relative;
  }
  .serv-tabs .content .curent {
    display: block;
  }
  .serv-tabs .content .bord {
    padding-right: 30px;
  }
  .serv-tabs .content .bord:after {
    content: '';
    width: 1px;
    height: 100%;
    background: #2154cf;
    position: absolute;
    right: 1px;
    top: 0;
    opacity: .7;
  }
  .serv-tabs .content .spcial {
    padding-left: 30px;
  }
  .serv-tabs .content .spcial p {
    color: #fff;
    font-size: 16px;
    font-style: italic;
    word-spacing: 1px;
  }
  .serv-tabs .tabs-icon .item div {
    text-align: center;
    padding: 20px 0;
    border: 1px solid rgba(255,255,255,.05);
    border-radius: 5px;
    cursor: pointer;
  }
  .serv-tabs .tabs-icon .item .icon {
    font-size: 30px;
    color: #fff;
    margin-bottom: 15px;
  }
  .serv-tabs .tabs-icon .item h6 {
    font-size: 14px;
    font-family: 'Poppins' , sans-serif;
    letter-spacing: -1px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .serv-tabs .tabs-icon .active div {
    background: #2154cf;
  }
  .serv-tabs .tabs-icon .active h6 {
    color: #fff;
  }
  
  /* ----------------------------------------------------------------
       [ End Services Tabs ]
  -----------------------------------------------------------------*/
  
  /* ----------------------------------------------------------------
       [ 10 Start Team ]
  -----------------------------------------------------------------*/
  .team .titem .team-img {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    width: 220px;
    height: 220px;
    margin: auto;
    position: relative;
  }
  .team .titem .team-img:before {
    content: '';
    top: 0;
    left: 0;
    background: rgba(33,84,207,0.8);
    overflow: hidden;
    width: 100%;
    height: 100%;
    /* -webkit-transform:scale(0); */
    transition: .3s ease;
    position: absolute;
  }
  .team .titem img {
    padding: 0;
  }
  .team .titem h6 {
    font-family: 'Poppins' , sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 18px;
    color: #686876;
    margin: 20px 0 0px;
  }
  .team .titem a:hover {
    color: #2154cf;
  }
  .team .titem span {
    font-size: 12px;
    font-style: italic;
    color: #959595;
    margin: 0;
    padding: 0;
  }
  .team .titem .social {
    width: 100%;
    height: 100%;
    margin: 15px 0;
    top: 50%;
    margin-top: -5px;
    left: 0;
    /* -webkit-transform:scale(0); */
    overflow: hidden;
    position: absolute;
  }
  .team .titem .social a {
    margin: 0 5px;
    font-size: 16px;
    color: #fff;
  }
  .team .titem .social a:hover {
    color: #000;
  }
  .team .titem:hover .team-img:before,
  .team .titem:hover .social {
    transform: scale(1);
  }
  /* ----------------------------------------------------------------
       [ End Team ]
  -----------------------------------------------------------------*/
  
  /* ----------------------------------------------------------------
       [ 11 Start Price ]
  -----------------------------------------------------------------*/
  .pricing {
    padding-bottom: 80px;
  }
  
  .price-icon-wrapper {
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 30px;
  }
  
  .plan {
    border: 1px solid #eaeaea;
    border-radius: 4px;
  }
  .plan-hover {
    position: relative;
  }
  .plan-hover:before {
    content: '';
    left: 0;
    top: 0;
    width: 0;
    transition: .5s ease;
    height: 100%;
    background: #2154cf;
    position: absolute;
  }
  .plan ul {
    background: #fff;
    padding: 0;
    margin-bottom: 0;
  }
  .plan li {
    padding: 10px 30px;
    font-family: 'Poppins' , sans-serif;
    font-size: 16px;
    list-style: none;
    color: #626c84;
  }
  .plan .btn {
    margin: 25px 0 40px;
  }
  .plan.featured {
    background: #2154cf;
    color: #fff;
    padding: 40px 0;
    margin: -40px 0;
  }
  .plan.featured ul {
    background: none;
  }
  .plan.featured li {
    color: #fff;
  }
  .plan.featured  a {
    width: 130px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    color: #2154cf;
    border-radius: 30px;
    margin-top: 10px;
    position: relative;
  }
  .plan.featured .plan-name {
    color: #fff;
    font-family: 'gilroy', sans-serif;
  }
  .plan.featured .plan-price {
    color: #fff;
    font-family: 'gilroy', sans-serif;
  }
  .plan.featured .btn {
    background: #fff;
    color: #2154cf;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .plan.featured .btn:hover {
    background: #1c121d;
    color: #fff;
    padding-right: 20px;
  }
  .plan.plan-last {
    margin-bottom: 0;
  }
  
  .plan-name {
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    padding: 20px 0;
    color: #252a37;
    margin-bottom: 0;
    font-family: 'gilroy', sans-serif;
  }
  .plan-name small {
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    font-family: 'gilroy', sans-serif;
  }
  
  .plan-price {
    font-size: 48px;
    padding: 0 0;
    position: relative;
    color: #252a37;
    margin: 0 0 25px;
    font-family: 'gilroy', sans-serif;
  }
  .plan-price .currency {
    top: -0.9em;
    font-size: 50%;
    left: -0.01em;
    font-weight: 700;
    font-family: 'gilroy', sans-serif;
  }
  .plan-price sub {
    font-size: 20px;
    left: -0.6em;
    position: relative;
    font-family: 'gilroy', sans-serif;
  }
  .plan a {
    width: 130px;
    height: 40px;
    line-height: 40px;
    background: #2154cf;
    color: #fff;
    border-radius: 30px;
    margin: 40px 0;
    transition: .5s ease;
    position: relative;
  }
  .plan span {
    transition: .5s ease;
    padding-left: 5px;
    position: absolute;
    top: 1px;
    right: 28px;
    overflow: hidden;
    opacity: 0;
  }
  .plan a:hover span {
    right: 14px;
    opacity: 1;
  }
  .ts-price-box {
    margin-top: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: -80px;
  }
  
  .pricing-boxed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .pricing-boxed:after {
    content: "";
    clear: both;
    display: block;
  }
  
  .single-price-box {
    width: 33.3333%;
    text-align: center;
  }
  .single-price-box .pricing-header_about_inner {
    min-height: 145px;
    padding: 10px 0;
    margin-bottom: 70px;
    background: #000;
    position: relative;
    z-index: 3;
  }
  .single-price-box .pricing-header_about_inner h3,
  .single-price-box .pricing-header_about_inner h2 {
    color: #fff;
  }
  .plan-hover:hover:before {
    width: 100%;
  }
  .plan:hover .plan-name,
  .plan:hover .plan-price,
  .plan:hover li {
    color: #fff;
    position: relative;
  }
  .plan:hover a {
    background: #fff;
    color: #2154cf;
    position: relative;
  }
  .plan:hover a:hover {
    background: #222;
    color: #fff;
    padding-right: 20px;
  }
  @media screen and (min-width: 992px) {
    .single-price-box .pricing-header_about_inner.border-left {
      border-top-left-radius: 5px;
    }
    .single-price-box .pricing-header_about_inner.border-right {
      border-top-right-radius: 5px;
    }
  }
  .single-price-box.featured {
    position: relative;
    z-index: 2;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding-bottom: 30px;
    background: #fff;
    margin-top: -30px;
  }
  .single-price-box.featured .pricing-header_about_inner {
    margin-bottom: 100px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #FC6B2A;
  }
  .single-price-box.featured .btn-primary {
    background: #FC6B2A;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .single-price-box.featured .btn-primary:hover {
    background: #000;
  }
  .single-price-box .pricing-plan .list-unstyled li {
    padding-bottom: 12px;
  }
  .single-price-box .pricing-plan .list-unstyled li:last-child {
    padding-bottom: 35px;
  }
  .single-price-box .pricing-plan {
    padding-bottom: 40px;
    background: #fff;
  }
  .padd-50 {
      padding: 0px 0px 150px 0;
  }
  /* ----------------------------------------------------------------
       [ End Price ]
  -----------------------------------------------------------------*/
  /* ----------------------------------------------------------------
       [ 12 Start Testimonails ]
  -----------------------------------------------------------------*/
 
 
  
  
  /*-- Owl slide common --*/
  .owl-carousel.page-slider .owl-nav {
    margin: 0;
  }
  
  .owl-carousel .owl-nav {
    text-align: center;
  }
  
  .owl-carousel.owl-theme .owl-nav [class*=owl-] {
    background: #2154cf;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 36px;
    font-size: 20px;
    text-align: center;
    margin: 0;
    border-radius: 0;
    -webkit-transition: 400ms;
    transition: 400ms;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .owl-carousel.owl-theme .owl-nav [class*=owl-prev] {
    right: 40px;
  }
  
  .owl-carousel.owl-theme .owl-nav > div:hover {
    background: #272d33;
  }
  
  .owl-theme .owl-dots {
    text-align: center;
    margin-top: 40px;
    position: relative;
    z-index: 1;
  }
  
  
  .testimonial-slide.owl-carousel.owl-theme .owl-nav [class*=owl-] {
    left: 0;
    top: 50%;
  }
  
  .testimonial-slide.owl-carousel.owl-theme .owl-nav [class*=owl-next] {
    left: auto;
    right: -200px;
  }
  
  .testimonial-slide.owl-carousel.owl-theme .owl-nav [class*=owl-prev] {
    right: auto;
    left: -200px;
  }
  
  .testimonial-slide .owl-nav {
    position: absolute;
    width: 100%;
    top: 50%;
  }
  
  
  .clients-area {
    background: #f1f1f1;
    padding: 70px 0;
  }
  
  .partner-logo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    min-height: 32px;
    margin-bottom: 0;
  }
  .partner-logo img {
    -webkit-transition: 350ms;
    transition: 350ms;
    transition: 350ms;
    width: auto !important;
  }
  .partner-logo:hover img {
    opacity: 1;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: grayscale(0%);
  }
  
  .owl-carousel.owl-theme.partners .owl-nav .owl-prev {
    left: 0;
    right: auto;
    background: #2154cf;
    color: #fff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .owl-carousel.owl-theme.partners .owl-nav .owl-prev:hover {
    background: #272D33;
  }
  .owl-carousel.owl-theme.partners .owl-nav [class*=owl-] {
    bottom: 0;
    margin-bottom: -12px;
  }
  
  /* ----------------------------------------------------------------
       [ End Testimonails ]
  -----------------------------------------------------------------*/
  /* ----------------------------------------------------------------
       [ 13 Start Blog ]
  -----------------------------------------------------------------*/
  .blog .item {
    -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
            box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
    border-radius: 5px;
  }
  .blog .item .post-img {
    position: relative;
    overflow: hidden;
  }
  
  .blog .item .post-img img {
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  .blog .item .post-img .date {
    display: inline-block;
    position: absolute;
    top: 15px;
    left: 15px;
    background: #2154cf;
    color: #fff;
    padding: 10px 15px;
    text-align: center;
  }
  .blog .item .post-img .date span {
    display: block;
    font-weight: 700;
    font-size: 12px;
  }
  .blog .item .content {
    padding: 0 5px 40px 5px;
    background: #fff;
  }
  
  .blog .item .content h5 {
    font-family: 'Poppins' , sans-serif;
    color: #686876;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .blog .item .content h5 a:hover {
    color: #2154cf;
  }
  .blog .item .content li {
    display: inline-block;
    padding-right: 10px;
    color: #777;
  }
  .blog .item .content li a:hover {
    color: #2154cf;
  }
  .blog .item .content .read-btn {
    margin-top: 15px;
    padding: 0;
  }
  .blog .item .content .read-btn a {
    font-family: 'Poppins' , sans-serif;
    font-weight: 500;
    font-size: 13px;
    padding: 10px 30px;
    color: #535353;
    text-transform: uppercase;
    background: #f1f1f1;
    border-radius: 30px;
    position: relative;
  }
  .blog .item .content .read-btn b {
    font-family: 'Poppins' , sans-serif;
    font-weight: 700;
    position: relative;
    z-index: 19;
  }
  .blog .item .content .read-btn a:before {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    border-radius: 30px;
    bottom: 0;
    left: 100%;
    z-index: 1;
    background: #2154cf;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .blog-nav {
    position: static !important;
    background: #fff !important;
    margin: 0;
  }
  .blog-nav .logo {
    color: #1c1c1c !important;
  }
  .blog-nav .navbar-nav li a {
    color: #1c1c1c !important;
    font-weight: 500;
  }
  .blog .item:hover .post-img img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .blog .item:hover .content .read-btn a:before {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  .blog .item:hover .content .read-btn b {
    color: #fff;
  }
  .min-header_about_inner {
    height: 40vh;
    background: #1c1c1c;
    position: relative;
  }
  .min-header_about_inner h5 {
    font-weight: 800;
    letter-spacing: 1px;
    color: #ccc;
    margin-bottom: 15px;
  }
  .min-header_about_inner a {
    position: relative;
    color: #ddd;
  }
  .min-header_about_inner a:after {
    content: '/';
    position: relative;
    margin: 0 10px;
  }
  .min-header_about_inner a:last-child:after {
    display: none;
  }
  
  .blogs .posts .post {
    margin-bottom: 50px;
    border-bottom: 1px solid #eee;
  }
  .blogs .posts .post .content {
    padding: 50px 15px;
    background: #fff;
  }
  .blogs .posts .post .content .post-title h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  .blogs .posts .post .content .meta {
    margin-bottom: 20px;
  }
  .blogs .posts .post .content .meta li {
    display: inline-block;
    font-size: 12px;
    color: #777;
    margin: 5px;
  }
  .blogs .posts .post .content p {
    font-weight: 300;
  }
  .blogs .posts .post .content .spical {
    padding: 15px;
    margin: 30px 0;
    border-left: 2px solid #111;
    background: #f7f7f7;
    font-size: 16px;
  }
  .blogs .posts .post .content .butn {
    display: inline-block;
    margin-top: 30px;
    padding: 8px 30px;
    border: 2px solid #ddd;
    font-weight: 500;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  .blogs .posts .post .content .butn:hover {
    background: #2154cf;
    border-color: #2154cf;
    color: #fff;
  }
  .blogs .posts .post .share-post {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px dashed #eee;
    text-align: left;
  }
  .blogs .posts .post .share-post span {
    font-weight: 700;
  }
  .blogs .posts .post .share-post ul {
    float: right;
  }
  .blogs .posts .post .share-post ul li {
    display: inline-block;
    margin: 0 10px;
  }
  .blogs .posts .title-g h3 {
    font-weight: 800;
    font-size: 25px;
  }
  .blogs .posts .comments-area .comment-box {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid #eee;
  }
  .blogs .posts .comments-area .comment-box:nth-child(odd) {
    margin-left: 80px;
  }
  .blogs .posts .comments-area .comment-box:last-child {
    margin-bottom: 0;
  }
  .blogs .posts .comments-area .comment-box .author-thumb {
    width: 80px;
    float: left;
  }
  .blogs .posts .comments-area .comment-box .comment-info {
    margin-left: 100px;
  }
  .blogs .posts .comments-area .comment-box .comment-info h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .blogs .posts .comments-area .comment-box .comment-info .reply {
    margin-top: 10px;
    font-weight: 600;
  }
  .blogs .posts .comments-area .comment-box .comment-info .reply i {
    padding-right: 5px;
    font-size: 12px;
  }
  .blogs .posts .comment-form .form input[type='text'],
  .blogs .posts .comment-form .form input[type='email'],
  .blogs .posts .comment-form .form textarea {
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    background: #f4f4f4;
  }
  .blogs .posts .comment-form .form textarea {
    height: 160px;
    max-height: 160px;
    max-width: 100%;
  }
  .blogs .posts .comment-form .form button[type='submit'] {
    background: #2154cf;
    border: 1px solid #2154cf;
    color: #fff;
    padding: 10px 30px;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
  }
  .blogs .posts .comment-form .form button[type='submit']:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 1;
    background: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .blogs .posts .comment-form .form button[type='submit'] span {
    position: relative;
    z-index: 2;
  }
  .blogs .posts .comment-form .form button[type='submit']:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  .blogs .posts .comment-form .form button[type='submit']:hover span {
    color: #2154cf;
  }
  .blogs .posts .pagination {
    display: block;
    text-align: center;
  }
  .blogs .posts .pagination li {
    display: inline-block;
    padding: 5px 10px;
    margin: 2px;
    border: 1px solid #ddd;
  }
  .blogs .side-bar .widget {
    margin-bottom: 50px;
  }
  .blogs .side-bar .widget:last-child {
    margin-bottom: 0;
  }
  .blogs .side-bar .widget .widget-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
  }
  .blogs .side-bar .widget .widget-title h6 {
    position: relative;
    padding-bottom: 15px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .blogs .side-bar .widget .widget-title h6:after {
    content: '';
    width: 30px;
    height: 1px;
    background: #000;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .blogs .side-bar .widget li {
    margin-bottom: 10px;
    color: #777;
  }
  .blogs .side-bar .widget li:last-child {
    margin: 0;
  }
  .blogs .side-bar .search form input {
    width: calc(100% - 52px);
    height: 50px;
    padding: 0 10px;
    border: 0;
    background: #f7f7f7;
  }
  .blogs .side-bar .search form button {
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #1c1c1c;
    color: #fff;
    border: 0;
    float: right;
  }
  
  .our-blog {
    margin: 0;
    padding: 0;
  }
  .our-blog .img-info {
    overflow: hidden;
  }
  .our-blog img {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    transition: .8s ease;
  }
  .our-blog h2 {
    font-family: 'Poppins' , sans-serif;
    font-size: 20px;
    text-align: center;
    padding: 16px 0;
  }
  .our-blog p {
    text-align: center;
    margin-bottom: 30px;
  }
  .our-blog a {
    width: 100px;
    height: 30px;
    border-radius: 30px;
    background-color: none;
    border: 2px solid #2154cf;
    color: #fff;
    text-align: center;
    line-height: 26px;
    transition: .5s ease;
    position: relative;
  }
  .our-blog i {
    transition: .5s ease;
    padding-left: 5px;
    position: absolute;
    top:6px;
    right: 28px;
    overflow: hidden;
    opacity: 0;
  }
  .our-blog:hover i {
    right: 14px;
    opacity: 1;
  }
  .our-blog:hover a {
    background: #2154cf;
    padding-right: 18px;
  }
  .our-blog:hover img {
    transform: scale(1.3);
  }
  .about-title {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .about-title h3 {
    font-family: 'Poppins' , sans-serif;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
  }
  .about-title h2 {
    font-family: 'Poppins' , sans-serif;
    line-height: 36px;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 20px;
  }
  .about-title.bot-30 {
    margin-bottom: 30px;
  }
  .development-title {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .development-title h3 {
    font-family: 'Poppins' , sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .development-title h2 {
    font-family: 'Poppins' , sans-serif;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -1px;
    margin: 0;
  }
  /* ----------------------------------------------------------------
       [ End Blog ]
  -----------------------------------------------------------------*/
  /* ----------------------------------------------------------------
       [ 14 Start Clients ]
  -----------------------------------------------------------------*/
  .clients {
    padding: 30px 0;
  }
  .clients .brand {
    padding: 15px;
    opacity: .5;
  }
  .clients .brand:hover {
    opacity: 1;
  }
  
  /* ----------------------------------------------------------------
       [ End Clients ]
  -----------------------------------------------------------------*/
  
  
  /* ----------------------------------------------------------------
       [ Start call-to-action ]
  -----------------------------------------------------------------*/
  
  .call-to-action {
    width: 100%;
    height: 150px;
    background: #2154cf;
    padding: 0;
    margin: 0;
    margin-top: 50px;
  }
  .call-to-action .txt-info {
    width: 85%;
    display: table;
    height: 150px;
  }
  .call-to-action .txt-info h2 {
    font-size: 'Poppins' , sans-serif;
    letter-spacing: -1px;
    font-weight: 700;
    vertical-align: middle;
    display: table-cell;
    color: #fff;
    font-size: 28px;
  }
  .call-to-action .txt-info h2 span {
    font-size: 'Poppins' , sans-serif;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    color: #fff;
    letter-spacing: 0;
  }
  .call-to-action .btnInfo  {
    width: 15%;
    display: table;
    height: 150px;
  }
  .call-to-action .btnInfo h2 {
    vertical-align: middle;
    display: table-cell;
    width: 100%;
    color: #2154cf;
  }
  .call-to-action .btnInfo h2 a {
    background: #fff;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    position: relative;
    transition: .5s ease;
    border-radius: 30px;
  }
  .call-to-action .btnInfo span {
    transition: .5s ease;
    padding-left: 5px;
    position: absolute;
    top:0px;
    right: 28px;
    overflow: hidden;
    opacity: 0;
  }
  .call-to-action .btnInfo:hover span {
    right: 24px;
    opacity: 1;
  }
  .call-to-action .btnInfo:hover h2 a {
    padding-right: 30px;
  }
  
  
  
  
  
  
  
  /* Sidebar
  ================================================== */
  .sidebar .widget-title {
    font-size: 24px;
    position: relative;
    margin: 0 0 30px;
    padding-left: 15px;
    border-left: 3px solid #2154cf;
  }
  .sidebar .widget {
    margin-bottom: 40px;
    border: 1px solid #f1f1f1;
    padding: 40px;
  }
  .sidebar .widget.widget-ad {
    padding: 0;
    border: none;
  }
  
  /* Widget common */
  .sidebar-left .widget {
    margin-right: 20px;
  }
  
  .sidebar-right .widget {
    margin-left: 20px;
  }
  
  .sidebar .widget.box {
    padding: 25px;
  }
  
  .widget.box.solid {
    background: #f2f2f2;
  }
  .widget.box.red {
    background: #ec483b;
    color: #fff;
  }
  .widget.box.red .widget-title {
    color: #fff;
  }
  .widget ul li {
    line-height: 30px;
  }
  
  .sidebar .widget ul li a:hover {
    color: #2154cf;
  }
  .sidebar .widget ul li i {
    margin-right: 5px;
    font-weight: 500;
  }
  .sidebar .btn {
    font-weight: 800;
    margin-top: 15px;
  }
  
  /* Widget search */
  .widget.widget-search {
    background: none;
    position: relative;
    padding: 0;
  }
  
  .input-group-btn {
    background: #2154cf;
    color: #fff;
  }
  
  .widget-search .form-control {
    border: 0 !important;
    font-style: italic;
    background-color: none;
  }
  .widget-search .form-control:focus {
    border: 0 !important;
    font-style: italic;
    background-color: none;
  }
  .widget-search i {
    color: #fff;
    background: #2154cf;
    font-size: 18px;
    position: relative;
    top: 0;
    padding: 15px;
  }
  
  /* Sidebar nav */
  .widget-nav-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .widget-nav-tabs li {
    display: block;
    padding-bottom: 10px;
    border-bottom: 1px dashed #EAEAEA;
    margin-bottom: 10px;
  }
  .widget-nav-tabs li a {
    font-size: 16px;
    color: #626c84;
    font-family: 'Poppins', sans-serif;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .widget-nav-tabs li span {
    float: right;
  }
  .widget-nav-tabs li:after {
    display: block;
    content: "";
    clear: both;
  }
  .widget-nav-tabs li:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
  
  /* News Listing
  ================================================== */
  
  .blog-container {
    padding: 0;
    margin: 50px 0;
  }
  .post {
    margin: 0 0 50px;
  }
  .post.last {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
  
  .post-body {
    padding: 25px 40px 40px 25px;
    border: 0;
    border-top: 0;
    background: #f7f9fd;
  }
  
  /* Post Meta Left */
  .post-meta-left {
    width: 15%;
    margin-top: -20px;
  }
  .post-meta-left span {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
  }
  
  /* Post content right */
  .post-content-right {
    width: 80%;
    float: right;
  }
  .post-content-right a.blog-read {
    font-family: 'Poppins' , sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 30px;
    background: #2154cf;
    color: #fff;
    border-radius: 30px;
    margin-top: 30px;
  }
  .post-content-right a.blog-read i {
    padding-left: 5px;
  }
  .post-content-right a.blog-read:hover {
    background: #000;
  }
  .entry-header_about_inner .entry-title {
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 15px;
  }
  .entry-header_about_inner .entry-title a {
    letter-spacing: -1px;
    font-weight: 700;
    color: #252a37;
    line-height: 40px;
  }
  .entry-header_about_inner .entry-title a:hover {
    color: #2154cf;
  }
  
  /* Post media */
  .post-media.post-video {
    max-width: 100%;
    position: relative;
    overflow: hidden;
  }
  .post-media.post-video:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .video-icon {
    text-align: center;
    color: #fff;
    display: block;
    top: 50%;
    margin-top: -50px;
    font-size: 78px;
    z-index: 1999;
    position: absolute;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    line-height: 65px;
    display: inline-block;
    border-radius: 100%;
    -webkit-transition: -webkit-transform .8s ease-in-out;
            transition:         transform .8s ease-in-out;
  }
  .video-icon i {
    -webkit-transition: 400ms;
    transition: 400ms;
    transition: 400ms;
  }
  .video-icon i:hover {
    color: #2154cf;
  }
  
  /* Meta date */
  .post-meta-date {
    background: #2154cf;
    color: #fff;
    width: 65px;
    height: 65px;
    line-height: 26px;
    padding: 10px 0;
    position: relative;
    top: -40px;
  }
  .post-meta-date span {
    font-size: 30px;
    font-weight: 600;
    display: block;
  }
  
  /* Post Quote */
  .post-quote-wrapper {
    position: relative;
    color: #fff;
    background: #252a37;
    padding: 50px;
  }
  
  .post-quote-content:before {
    padding: 28px 0 22px;
    content: "\e937";
    font-family: 'iconfont';
    color: #fff;
    font-size: 44px;
  }
  .post-quote-content .entry-title {
    margin-top: 20px;
  }
  .post-quote-content .entry-title a {
    color: #fff;
  }
  .post-quote-content .meta-author {
    color: #ccc;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    margin-bottom: 15px;
  }
  .post-quote-content .meta-author:before {
    content: '';
    position: absolute;
    top: 14px;
    height: 2px;
    width: 20px;
    background-color: #2154cf;
    margin-left: -30px;
  }
  .post-quote-content .post-meta {
    color: #fff;
  }
  .post-quote-content .post-meta a {
    color: #fff;
  }
  
  /* Author */
  .post-author {
    position: relative;
  }
  .post-author:after {
    content: '';
    position: absolute;
    border-bottom: 1px solid #dedede;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 75px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .post-author .avatar {
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 100%;
    margin-bottom: 5px;
  }
  
  .post-meta-left span.post-author {
    display: block;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  
  .post-author a {
    display: block;
  }
  
  .post-comment i {
    position: relative;
    bottom: -3px;
    margin-right: 5px;
  }
  
  /* Common post meta */
  .post-meta {
    padding-bottom: 15px;
    color: #626c84;
    font-size: 14px;
  }
  .post-meta a {
    color: #626c84;
    position: relative;
    z-index: 1;
  }
  
  .post-meta-left a {
    color: #626c84;
    position: relative;
    z-index: 1;
  }
  
  .post-meta a:hover,
  .post-meta-left a:hover {
    color: #2154cf;
  }
  
  .post-meta span {
    margin-right: 10px;
    padding-right: 10px;
    line-height: 12px;
    display: inline-block;
  }
  .post-meta i {
    padding-right: 0;
    margin-right: 6px;
    font-weight: 500;
  }
  
  .post-footer .btn.btn-primary {
    margin-top: 10px;
    padding: 10px 30px;
  }
  
  /*end customers css*/
  
  .media-left,
  .media > .pull-left {
    padding-right: 20px;
  }
  /* Recent News */
  .widget.recent-posts .widget-title {
    margin-bottom: 35px;
  }
  .widget.recent-posts ul li {
    border-bottom: 1px dashed #f1f1f1;
    padding-bottom: 20px;
    margin-bottom: 20px;
    line-height: normal;
  }
  .widget.recent-posts ul li:last-child {
    border: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .widget.recent-posts .media-left img {
    width: 93px;
    height: 65px;
  }
  .widget.recent-posts .entry-title {
    font-size: 16px;
    line-height: 20px;
    margin: 6px 0 0;
  }
  .widget.recent-posts .entry-title a {
    color: #303030;
    display: inline-block;
    padding: 5px 0;
  }
  .widget.recent-posts .entry-title a:hover {
    color: #2154cf;
  }
  .widget.recent-posts .entry-title small {
    color: #626c84;
    display: block;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }
  .widget.recent-posts .post-date {
    font-weight: 500;
    color: #626c84;
    font-size: 14px;
  }
  .widget.recent-posts .post-date a {
    color: #626c84;
  }
  /* Widget tags */
  .widget-tags ul > li {
    float: left;
    margin: 3px;
  }
  
  .sidebar .widget-tags ul > li a {
    border: 1px solid #f1f1f1;
    color: #626c84;
    display: inline-block;
    font-size: 14px;
    padding: 5px 20px;
    margin: 0 3px 3px 0;
    border-radius: 25px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .sidebar .widget-tags ul > li a:hover {
    background: #2154cf;
    color: #fff;
    border: 1px solid transparent;
  }
  /*-- Banner --*/
  .banner-area {
    position: relative;
    height: 400px;
    color: #fff;
    background-position: 50% 50%;
    background-size: cover;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .banner-area:before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(to top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%), url(../img/slider/8.jpg); */
    background-size: cover;
    background-position: center;
    position: absolute;
  }
  .banner-area .container {
    position: relative;
  }
  
  .banner-title {
    color: #fff;
    font-family: 'Poppins' , sans-serif;
    font-size: 74px;
    letter-spacing: -2px;
    margin-bottom: 30px;
  }
  
  .banner-heading {
    padding: 0px 0 0 0;
    width: 100%;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 400px;
  }
  
  .breadcrumb {
    bottom: 0;
    padding: 0;
    background: none;
    font-size: 16px;
    font-weight: 300;
  }
  .breadcrumb a {
    color: #fff;
  }
  .breadcrumb i {
    padding:0 10px;
    color: #2154cf;
  }
  .breadcrumb a:hover {
    color: #2154cf;
  }
  
  
  /* News Single
  ================================================== */
  .post-single {
    position: relative;
  }
  .post-single .post-body {
    border: 1px solid #f1f1f1;
    background: none;
    padding: 40px;
  }
  .post-single .post-meta-date {
    position: absolute;
    top: 30px;
    left: 35px;
    display: block;
    text-align: center;
  }
  .post-single .post-author .avatar {
    width: 30px;
    height: 30px;
  }
  .post-single .post-author a {
    display: inline-block;
    margin-left: 6px;
  }
  .post-single .post-author:after {
    border: 0;
  }
  .post-single .entry-header_about_inner .entry-title {
    font-size: 36px;
  }
  .post-single .entry-content p {
    margin-bottom: 30px;
  }
  .post-single .entry-content h3 {
    margin: 30px 0;
  }
  .post-single .post-footer {
    border-top: 1px solid #f1f1f1;
    margin: 40px 0 0;
    padding-top: 40px;
  }
  
  .tags-area {
    margin: 20px 0;
  }
  
  .post-tags strong {
    margin-right: 5px;
  }
  .post-tags a {
    border: 1px solid #f1f1f1;
    color: #626c84;
    display: inline-block;
    font-size: 14px;
    padding: 3px 15px;
    margin-left: 3px;
    border-radius: 25px;
  }
  .post-tags a:hover {
    background: #2154cf;
    color: #fff;
    border: 1px solid transparent;
  }
  
  /* Post social */
  .post-social-icons > li {
    display: inline-block;
  }
  .post-social-icons a {
    margin-left: 10px;
    font-size: 16px;
    color: #252a37;
    text-align: center;
  }
  .post-social-icons a:hover {
    color: #2154cf;
  }
  
  /* Post navigation */
  .post-navigation span:hover,
  .post-navigation h3:hover {
    color: #2154cf;
  }
  .post-navigation .post-previous,
  .post-navigation .post-next {
    border-left: 1px solid #f1f1f1;
    padding: 0 40px;
    width: 50%;
    border-right: 1px solid #f1f1f1;
    display: table-cell;
    position: relative;
    vertical-align: middle;
  }
  .post-navigation i {
    margin: 0 5px;
    color: #2154cf;
  }
  .post-navigation span {
    font-size: 14px;
    color: #626c84;
    margin-bottom: 10px;
  }
  .post-navigation .post-previous {
    text-align: left;
    float: left;
    border-left: 0 none;
    border-right: 0 none;
    padding: 0 40px 0 0;
  }
  .post-navigation .post-next {
    text-align: right;
    float: left;
    border-right: 0 none;
    padding: 0 0 0 40px;
  }
  .post-navigation h3 {
    font-weight: 700;
    letter-spacing: 0;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .post-navigation a {
    font-size: 'Poppins' , sans-serif;
    font-weight: 500;
  }
  /* Author box */
  .author-box {
    padding: 40px;
    margin: 40px 0;
  }
  
  .author-img img {
    width: 100px;
    height: 100px;
    margin-right: 30px;
    border-radius: 100%;
  }
  
  .author-info h3 {
    font-family: 'Poppins' , sans-serif;
    color: #252a37;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
  }
  .author-info h3 span {
    font-size: 12px;
    color: #999;
    border-left: 1px solid #AFAFAF;
    padding-left: 10px;
    margin-left: 10px;
    font-weight: 500;
  }
  .author-info p {
    padding-left: 130px;
  }
  
  .author-url a {
    font-size: 14px;
    color: #626c84;
  }
  
  /* Comments area */
  
  .comments-area {
    margin: 40px 0;
  }
  
  .comments-list .comment-content {
    margin: 15px 0;
  }
  .comments-list .comment-reply {
    color: #252a37;
    font-weight: 400;
    font-size: 14px;
  }
  .comments-list .comment-reply i {
    margin-right: 5px;
  }
  .comments-list .comment-reply:hover {
    color: #2154cf;
  }
  
  .comments-counter {
    font-size: 18px;
  }
  .comments-counter a {
    color: #323232;
  }
  
  .comments-list {
    list-style: none;
    margin: 0;
    padding: 20px 0;
  }
  .comments-list .comment {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .comments-list .comment.last {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .comments-list img.comment-avatar {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-right: 30px;
  }
  .comments-list .comment-body {
    margin-left: 110px;
  }
  .comments-list .comment-author {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 700;
    font-size: 18px;
    color: #252a37;
  }
  .comments-list .comment-date {
    color: #252a37;
    font-weight: 400;
    font-size: 14px;
    display: block;
  }
  
  .comments-reply {
    list-style: none;
    margin: 0 0 0 70px;
  }
  
  .comments-form {
    margin-bottom: 0;
  }
  .comments-form .title-normal {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .comments-form .btn.btn-primary {
    margin-top: 20px;
  }
  
  
  /* Input form */
  .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #f1f1f1;
    padding: 5px 20px;
    height: 50px;
    background: none;
    color: #626c84;
    font-size: 14px;
    border-radius: 0;
    font-weight: normal;
  }
  .form-control.color-1 {
    border: 1px solid #6387dd;
    color: #fff !important;
  }
  .form-control.color-1::placeholder{
    color: #fff;
  }
  .form-control.color-1:-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: #fff;
  }
  .form-control.color-1::-ms-input-placeholder { /* Microsoft Edge */
     color: #fff;
  }
  .form-control.color-1:focus {
    background: #2154cf;
  }
  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #F1F1F1;
  }
  
  input:focus,
  textarea:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #F1F1F1;
  }
  .btn.btn-primary,
  .btn-dark,
  .btn-border,
  .btn-white {
    font-size: 16px;
    font-weight: 600;
    padding: 15px 40px;
    line-height: 1.33;
    border-radius: 30px;
    border: 0;
    -webkit-transition: color 400ms, background-color 400ms, border-color 400ms;
    transition: color 400ms, background-color 400ms, border-color 400ms;
    transition: color 400ms, background-color 400ms, border-color 400ms;
    position: relative;
  }
  
  .btn-white.btn-primary {
    background: #fff;
    color: #2154cf;
  }
  
  .btn.btn-primary {
    background: #2154cf;
    border: 2px solid transparent;
  }
  .btn-primary:hover,
  .btn-dark:hover,
  .btn-white.btn-primary:hover {
    background: #0f2765;
    color: #fff;
  }
  /* Pagination */
  
  .paging {
    margin-bottom: -5px;
  }
  
  .pagination {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
  }
  .pagination > li > a,
  .pagination > li > span {
    border-radius: 100% !important;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    line-height: 28px;
    color: #7c7c7c;
    border: 1px solid #f1f1f1;
    text-align: center;
    display: inline-block;
    line-height: 40px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .pagination > .active > a {
    color: #fff;
    background: #2154cf;
    border: 1px solid transparent;
  }
  .pagination > .active > a:hover {
    color: #fff;
    background: #2154cf;
    border: 1px solid transparent;
  }
  .pagination > li > a:hover {
    color: #fff;
    background: #2154cf;
    border: 1px solid transparent;
  }
  
  /* ----------------------------------------------------------------
       [ 15 Start Contact ]
  -----------------------------------------------------------------*/
  
  
  /* Contact Us */
  
  .map {
    height: 480px;
    position: relative;
  }
  .contact #ieatmaps {
    height: 100%;
  }
  .contact .info {
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 80px 50px;
    color: #fff;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  .contact .info .icon-toggle {
    width: 100px;
    height: 100px;
    background: #111;
    font-size: 20px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 70px);
    right: -10px;
    border-radius: 5px;
    z-index: -1;
    cursor: pointer;
  }
  .contact .info .icon-toggle i {
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
    position: absolute;
    right: 2px;
    top: 4px;
  }
  .contact .info .item {
    margin-bottom: 50px;
  }
  .contact .info .item:last-child {
    margin-bottom: 0;
  }
  .contact .info .item .icon {
    font-size: 45px;
    float: left;
  }
  .contact .info .item .cont {
    margin-left: 60px;
  }
  .contact .info .item .cont h6 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .contact .info .item .cont p {
    color: #999;
  }
  .contact .map-show {
    left: -100%;
  }
  .contact .map-show .icon-toggle {
    z-index: 2;
    -webkit-transition-delay: .3s;
            transition-delay: .3s;
  }
  .contact .contact-form {
    padding: 40px;
    background: #222;
    margin-top: -450px;
    margin-bottom: 40px;
  }
  .contact .contact-form input[type='text'],
  .contact .contact-form input[type='email'],
  .contact .contact-form textarea {
    width: 100%;
    color: #fff;
    padding: 10px 0;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #4b4b4b;
  }
  .contact .contact-form input[type='text']:focus,
  .contact .contact-form input[type='email']:focus,
  .contact .contact-form textarea:focus {
    border-bottom: 1px solid #2154cf;
  }
  .contact .contact-form textarea {
    max-width: 100%;
  }
  .contact .contact-form button[type='submit'] {
    background: #2154cf;
    border: none;
    color: #fff;
    padding: 15px 30px;
    border-radius: 30px;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
  }
  .contact .contact-form button[type='submit']:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    border-radius: 30px;
    bottom: 0;
    left: 100%;
    z-index: 1;
    background: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .contact .contact-form button[type='submit'] span {
    position: relative;
    z-index: 2;
  }
  .contact .contact-form button[type='submit']:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  .contact .contact-form button[type='submit']:hover span {
    color: #2154cf;
  }
  
  /* ----------------------------------------------------------------
       [ End Contact ]
  -----------------------------------------------------------------*/
  
  /* RISE LAYERED ITEMS */
  
   /* .layered-items{
       padding: 70px 70px 190px 70px; 
  } */
   .layered-items .layer-container{
       position: relative;
       display: block;
       margin: 0;
       padding: 0;
  }
   .layered-items .layer-container .layer{
       position: absolute;
       left:0;
       top:0;
       width: 100%;
       height: 100%;
  }
   .layered-items .layer-container .layer img{
       display: block;
       max-width: 100%;
       margin:0;
       width: 100%;
  }
  /* arrow effect */
   .layered-items .layer-container .arrow-layer{
       -ms-transform: translateX(-140px);
       -webkit-transform: translateX(-140px);
       transform: translateX(-140px);
       -webkit-transition: all 0.4s;
       -moz-transition: all 0.4s;
       transition: all 0.4s;
  }
   .layered-items .layer-container:hover .arrow-layer{
       -ms-transform: translateX(-20px);
       -webkit-transform: translateX(-20px);
       transform: translateX(-20px);
  }
  /* image effect */
   .layered-items .layer-container .image-layer{
       -ms-transform: translateX(0);
       -webkit-transform: translateX(0);
       transform: translateX(0);
       -webkit-transition: all 0.4s;
       -moz-transition: all 0.4s;
       transition: all 0.4s;
  }
   .layered-items .layer-container:hover .image-layer{
       -ms-transform: translateX(-20px);
       -webkit-transform: translateX(-20px);
       transform: translateX(-20px);
  }
  
  #back-to-top {
    position: fixed;
    right: 40px;
    top: auto;
    z-index: 10;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: none;
  }
  #back-to-top.affix {
    bottom: 25px;
  }
  #back-to-top .btn.btn-primary {
    font-size: 18px;
    padding: 6px 15px;
    width: 44px;
    height: 44px;
    line-height: 30px;
    border-radius: 100%;
  }
  
  .col-xs-2 {
    width: 16.666667%;
    height: 100vh;
    position: relative;
  }
  .col-xs-4 {
    width: 33.333333%;
    position: relative;
  }
  .col-xs-6 {
    width: 50%;
    height: 100vh;
    position: relative;
  }
  
  /* ----------------------------------------------------------------
       [ 16 Start Footer ]
  -----------------------------------------------------------------*/
  footer {
    background: #111;
    padding: 50px 0;
  }
  footer .social a {
    color: #777;
    font-size: 18px;
    margin: 15px 10px;
  }
  footer .social a:hover {
    color: #2154cf;
  }
  footer p {
    color: #777;
    font-weight: 300;
  }
  
  /* ----------------------------------------------------------------
       [ End Footer ]
  -----------------------------------------------------------------*/
  /* ----------------------------------------------------------------
        [ 17 Responsive ]
  -----------------------------------------------------------------*/
  @media screen and (max-width: 991px) {
    .mb-md50 {
      margin-bottom: 50px;
    }
  
    .mb-md30 {
      margin-bottom: 30px;
    }
  
    .mb-md0 {
      margin-bottom: 0;
    }
  
    .navbar .navbar-collapse {
      max-height: 340px;
      overflow: auto;
      background: #111;
      text-align: center;
      padding: 10px 0;
    }
    .navbar .nav-link {
      margin: 10px auto !important;
    }
  
    .nav-scroll .navbar-collapse .nav-link {
      color: #fff !important;
    }
    .nav-scroll .navbar-collapse .active {
      color: #2154cf !important;
    }
    .header_about_inner .caption h3 {
      font-size: 22px;
    }
    .header_about_inner .caption h2 {
      font-size: 30px;
    }
    .header_about_inner .caption h1 {
      font-size: 26px;
    }
    .header_about_inner .caption p {
      font-size: 16px;
      padding: 0 10px;
    }
  
    .serv-tabs .content .bord {
      padding-right: 0;
      margin-bottom: 50px;
    }
    .serv-tabs .content .bord:after {
      display: none;
    }
    .serv-tabs .content .spcial {
      padding-left: 0;
    }
  
    .contact .map {
      height: 500px;
    }
    .contact .info .icon-toggle {
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
      top: calc(100% - 80px);
      right: calc(50% - 65px);
    }
    .contact .info .icon-toggle i {
      -webkit-transform: rotate(-135deg) !important;
              transform: rotate(-135deg) !important;
      right: 4px;
    }
    .contact .map-show {
      top: -100%;
      left: 0;
    }
    
  }
  @media screen and (max-width: 767px) {
    .navbar {
      padding: 0 20px;
    }
    .navbar .navbar-nav.color-1 .nav-link {
      color: #eee;
    }
    .logo.center-logo {
      position: relative;
    }
    .padd-left {
      padding: 0;
    }
    .mb-sm50 {
      margin-bottom: 50px;
    }
  
    .mb-sm30 {
      margin-bottom: 30px;
    }
    .layer {
      display: none;
      visibility: visible;
    }
    .serv-tabs .tabs-icon .item div {
      padding: 15px 0;
    }
    .serv-tabs .tabs-icon .item h6 {
      display: none;
    }
    .layered-items {
      padding: 40px;
    }
    .cd-words-wrapper b {
      display: inline-block;
      position: absolute;
      white-space: nowrap;
      left: 0;
      top: 0;
      font-size: 35px
    }
   
   .mrg_20{
    margin-top: -20px;
   }
   .mrg_27per_video_inner{
     margin-top: 27%;
     font-size: 26px!important;
   }
}

/* IE css */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mrg_top_about_ie{
    margin-top: 18%!important;
  }
 }
 .overlay_hide_about:before{
  background:none!important
 }
 .bg_about_us{
 
    /* background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 50%, #1a1a1a 50%, #3d80e5 50%, #3d80e5 100%); */
    background-image: url(../img_new/about_us_inner/about_bg_img_new.png);
    height: 560px;
    min-height: 90vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
 }


 @media (min-width: 280px) and (max-width: 767px) {
  .bg_about_us{
    width: 100%;
    margin-top: 20%;
    height: 25vh;
    min-height: 25vh;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bg_about_us{
    margin-top: 4%;
    min-height: 33vh; 
    background-size: contain;
  }
 
  
}