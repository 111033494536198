.mobile_bg_img{
    width: 71%;
    /* float: right; */
    /* height: 900px; */
    /* background-image: url(./img_new/mobile-3640069_960_720.png); */
    background-size: 334px 696px;
    width: 500px;
    height: 731px;
    background-repeat: no-repeat;
}
.port_mobile_txt_color{
    color: #000;
    /* text-align: center */
    padding: 10px;
    text-align: justify
}
.pad_top_color{
    padding-top:2% 
}
.port_ptag_color{
    color: #000;
    padding: 10px;
    font-size: 14px
}
/* laptop css */
.labtop_bg_img{
    /* width: 119%;
    padding-top:11%  */
    width: 100%;
    /* float: right; */
    /* height: 900px; */
    /* background-image: url('./img_new/laptop_new.png'); */
    background-size: 106% 49%;
    /* width: 351px; */
    height: 696px;
    background-repeat: no-repeat;
}
.pad_top_color_laptop{
    padding-top:4% 
}
.port_laptop_txt_color{
    color: #000;
    /* text-align: center */
    padding: 10px;
    text-align: justify
}
.pad_top_color_laptop{
    padding-top:4% 
}
.port_ptag_color_laptop{
    color: #000;
    padding: 10px;
    font-size: 14px;
    text-align: justify
}
/* video for mobile css */
.video_inner_tag1{
    position: absolute;
    top: -73px;
    left: 17px;
    width: 100%;
    height: 83%;
    object-fit: fill;

}
.video_mobile1{
    position: absolute;
    left: 6px;
    top: 136px;
    width: 314px;
    height: 675px;
    /* outline: #000 solid 6px; */
}
/* video for laptop */

.video_inner_tag1_laptop{
    position: absolute;
    top: -73px;
    left: 17px;
    width: 100%;
    height: 83%;
    object-fit: fill;

}
.video_mobile1_laptop{
    position: absolute;
    left: 135px;
    top: 257px;
    width: 508px;
    height: 392px;
    /* outline: #000 solid 6px; */
}
.video_mobile1_laptop_frame{
    /* position: absolute; */
    left: 6px;
    top: 136px;
    width: 314px;
    height: 675px;
    /* outline: #000 solid 6px; */
}
/* button_port_color_more css */
/* body {
	align-items: center;
	background-color: #202020;
	background-image: radial-gradient(10% 100% ellipse at bottom center, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 100%);
	display: flex;
	height: 100vh;
	justify-content: center;
	margin: 0;
	padding: 0;
	transition: background-color 2s cubic-bezier(0.19, 1, 0.22, 1);
} */

.button_port_color_more {
	border: 1px solid #101f7d;
	cursor: pointer;
	letter-spacing: 0.2125rem;
	overflow: hidden;
	padding: 15px 26px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	transition: 
		background 5s cubic-bezier(0.19, 1, 0.22, 1),
		border 1s cubic-bezier(0.19, 1, 0.22, 1),
		color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    user-select: none;
    width:40%;
    display: block;
    margin: 0px auto;
    font-size: 18px
}

.button_port_color_more a {
	color: #101f7d;
	font-family: "Varela Round";
	text-decoration: none;
}

.button_port_color_more .mask {
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.5);
	height: 100px;
	position: absolute;
	transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
	transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
	width: 200px;
}

.button_port_color_more .shift {
	display: inline-block;
	transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button_port_color_more:hover {
	/* background-color: rgba(255, 255, 255, 0.05);
	border-color: #fff;
	box-shadow: 0 0 5px rgba(255, 245, 245, 0.8);
    transition: background 0s; */
    background-color: rgb(16, 31, 125);
    border-color: #101f7d;
    box-shadow: 0 0 5px rgba(255, 245, 245, 0.8);
    -webkit-transition: background 0s;
    transition: background 0s;
    color: #fff;
}

.button_port_color_more:hover a {
	color:#fff;
}

.button_port_color_more:hover .mask {
	background-color: #fff;
	transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
}

.button_port_color_more:hover .shift {
	transform: translateX(5px);
}

.button_port_color_more:active {
	background-color: #fff;
}

.button_port_color_more:active a {
	color: #202020;
}
.span_color_more_fns{
    font-size: 18px
}

/* body.show {
	background-color: #282828;
} */

/* .button_port_color_more:hover {
	background-image: url('');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
} */
/* third sections row css */
.mgrt_color_third{
    margin-top: -16%;
}
/* video for laptop2 */

.video_inner_tag1_laptop{
    position: absolute;
    left: 17px;
    width: 100%;
    height: 69%;
    object-fit: cover;

}
.video_mobile1_laptop{
    position: absolute;
    left: 135px;
    top: 257px;
    width: 508px;
    height: 392px;
    /* outline: #000 solid 6px; */
}
.video_mobile1_laptop_frame{
    /* position: absolute; */
    left: 6px;
    top: 136px;
    width: 314px;
    height: 675px;
    /* outline: #000 solid 6px; */
}
/* laptop css */
.desktop_bg_img{
    /* width: 119%;
    padding-top:11%  */
    width: 100%;
    /* float: right; */
    /* height: 900px; */
    /*background-image: url('./img_new/computer_desktop.png');*/
    background-size: 85% 75%;
    /* width: 351px; */
    height: 696px;
    background-repeat: no-repeat;
}
.pad_top_color_desktop{
    padding-top:7% 
}
.port_desktop_txt_color{
    color: #fff;
    /* text-align: center */
    padding: 10px;
    text-align: justify
}
.pad_top_color_desktop{
    padding-top:12% 
}
.port_ptag_color_desktop{
    color: #000;
    padding: 10px;
    font-size: 14px
}

/* media query  */
@media (min-width: 280px) and (max-width: 767px) {
  
    .video_inner_tag1_laptop {
        position: relative;
        top: -178px;
        left: 97px;
        width: 65%;
        height: 89%;
        object-fit: fill;
    }

.video_mobile1_laptop {
    position: absolute;
    left: 0px;
    top: 257px;
    width: 508px;
    height: 392px;
    /* outline: #000 solid 6px; */
  }
  .labtop_bg_img {
    width: 100%;
    /* float: right; */
    /* height: 900px; */
    /*background-image: url(./img_new/laptop_new.png);*/
    background-size: 500px 449px;
    width: 500px;
    height: 843px;
    background-repeat: no-repeat;
    /* /* margin-left: -28%; */
  }
  .mgrt_color_third{
      margin-top: 0%;
  }
}


/* tablet css */
/* laptop css */
.tablet_bg_img{
    /* width: 119%;
    padding-top:11%  */
    width: 100%;
    /* float: right; */
    /* height: 900px; */
    /*background-image: url('img_new/navigator_strata_1_tablet_frame.png');*/
    background-size: 85% 65%;
    /* width: 351px; */
    height: 696px;
    background-repeat: no-repeat;
}
.pad_top_color_tablet{
    padding-top:7% 
}
.port_tablet_txt_color{
    color: #fff;
    /* text-align: center */
    padding: 10px;
    text-align: justify
}
.pad_top_color_tablet{
    padding-top:12% 
}
.port_ptag_color_tablet{
    color: #fff;
    padding: 10px;
    font-size: 20px
}
/* video for tablet */
.video_inner_tag1_tablet{
    position: absolute;
    left: 17px;
    width: 100%;
    height: 131%;
    object-fit: fill;
}


.video_mobile1_tablet{
    position: absolute;
    left: 83px;
    top: 200px;
    width: 440px;
    height: 277px;
    /* outline: #000 solid 6px; */
}
.video_mobile1_tablet_frame{
    /* position: absolute; */
    left: 6px;
    top: 136px;
    width: 314px;
    height: 675px;
    /* outline: #000 solid 6px; */
}
/* desktop videos */
.video_inner_tag1_desktop{
    position: absolute;
    left: -56px;
    width: 100%;
    height: 90%;
    object-fit: fill;

}
.video_mobile1_desktop{
    position: absolute;
    left: 95px;
    top: 178px;
    width: 582px;
    height: 312px;
    /* outline: #000 solid 6px;*/
}
.video_mobile1_desktop_frame{
    position: absolute;
    left: 6px;
    top: 136px;
    width: 314px;
    height: 675px;
    /* outline: #000 solid 6px; */
}

/* extra css */

.row_divs_new {
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;
}
.claa_video {
    box-sizing: border-box;
    /*background: url(./img_new/navigator_strata_1_tablet_frame.png) center center no-repeat;*/
    background-size: contain;
    padding: 11.9% 15.5% 14.8%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;              
    height: 100%;
}
/* media querys for extra desktops */

@media (min-width: 1025px) and (max-width: 1280px) {
    .labtop_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        /*background-image: url(./img_new/laptop_new.png);*/
        background-size: 99% 47%;
        /* width: 351px; */
        height: 690px;
        background-repeat: no-repeat;
   }
   .video_mobile1_laptop {
    position: absolute;
    left: 97px;
    top: 244px;
    width: 454px;
    height: 362px;
    /* outline: #000 solid 6px;*/
   }
   .video_inner_tag1_laptop {
    position: absolute;
    left: 17px;
    width: 100%;
    height: 69%;
    object-fit: cover;
   }
   /* desktop media query */
   .video_mobile1_desktop {
    position: absolute;
    left: 91px;
    top: 163px;
    width: 529px;
    height: 312px;
    /* outline: #000 solid 6px; */
   }
   /* tablet querys */
   .video_mobile1_tablet {
    position: absolute;
    left: 50px;
    top: 178px;
    width: 440px;
    height: 278px;
    /* outline: #000 solid 6px; */
   }
  }
/* for media query 1280px */
@media (min-width: 1280px) and (max-width: 1360px) {
    .labtop_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        /*background-image: url(./img_new/laptop_new.png);*/
        background-size: 99% 47%;
        /* width: 351px; */
        height: 690px;
        background-repeat: no-repeat;
   }
   .video_mobile1_laptop {
    position: absolute;
    left: 16%;
    top: 30%;
    width: 454px;
    height: 362px;
    /* outline: #000 solid 6px; */
   }
   .video_inner_tag1_laptop {
    position: absolute;
    left: 17px;
    width: 100%;
    height: 69%;
    object-fit: cover;
   }
   /* desktop media query */
   .video_mobile1_desktop {
    position: absolute;
    left: 12%;
    top: 20%;
    width: 77%;
    height: 312px;
    /* outline: #000 solid 6px; */
   }
   /* tablet querys */
   .video_mobile1_tablet {
    position: absolute;
    left: 10%;
    top: 22%;
    width: 440px;
    height: 278px;
    /* outline: #000 solid 6px;*/
   }
  }




  @media (min-width: 1380px) and (max-width: 1589px) {
    .video_mobile1_laptop {
        position: absolute!important;
        left: 16%!important;
        top: 31%!important;
        width: 60%!important;
        height: 416px!important;
        /* outline: #000 solid 6px; */
    }
    .labtop_bg_img {
        width: 100%!important;
        /* float: right; */
        /* height: 900px; */
        /*background-image: url(./img_new/laptop_new.png);*/
        background-size: 95% 49%!important;
        /* width: 351px; */
        height: 696px!important;
        background-repeat: no-repeat
    }
    .video_inner_tag1_laptop {
        position: absolute!important;
        left: 17px!important;
        width: 100%!important;
        height: 69%!important;
        object-fit: cover!important;
    }
    .video_mobile1_desktop {
        position: absolute;
        left: 9%;
        top: 23%;
        width: 78%;
        height: 312px;
        /* outline: #000 solid 6px; */
    }
       .video_inner_tag1_desktop {
        position: absolute;
        left: -45px;
        width: 100%;
        height: 90%;
        object-fit: fill;
       }
       /* tablet query */
       .video_mobile1_tablet {
        position: absolute;
        left: 10%;
        top: 24%;
        width: 59%;
        height: 292px;
        /* outline: #000 solid 6px; */
      }
  }
  @media (min-width: 1690px) and (max-width: 1720px)  {
  .video_mobile1_laptop {
    position: absolute;
    left: 147px;
    top: 302px;
    width: 548px;
    height: 408px;
    /* outline: #000 solid 6px; */
  }
  .labtop_bg_img {
    width: 100%;
    /* float: right; */
    /* height: 900px; */
    /*background-image: url(./img_new/laptop_new.png);*/
    background-size: 90% 54%;
    /* width: 351px; */
    height: 696px;
    background-repeat: no-repeat;
  }
  .video_inner_tag1_laptop {
    position: absolute;
    left: 17px;
    width: 100%;
    height: 69%;
    object-fit: cover;
  }
  /* table query */
  .video_mobile1_tablet {
    position: absolute;
    left: 83px;
    top: 222px;
    width: 584px;
    height: 304px;
    /* outline: #000 solid 6px; */
  }
  /* desktop querys */
  .video_mobile1_desktop {
    position: absolute;
    left: 95px;
    top: 218px;
    width: 744px;
    height: 312px;
    /* outline: #000 solid 6px; */
  }
}
/* for small system media querys */
@media (min-width: 1590px) and (max-width: 1680px)  {
    .labtop_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        /*background-image: url(./img_new/laptop_new.png);*/
        background-size: 90% 54%;
        /* width: 351px; */
        height: 696px;
        background-repeat: no-repeat;
      }
    .video_mobile1_laptop {
        position: absolute;
        left: 13%;
        top: 302px;
        width: 548px;
        height: 408px;
        /* outline: #000 solid 6px; */
    }
    .video_mobile1_desktop {
        position: absolute;
        left: 9%;
        top: 23%;
        width: 79%;
        height: 312px;
        /* outline: #000 solid 6px; */
    }
    .video_mobile1_tablet {
        position: absolute;
        left: 7%;
        top: 24%;
        width: 584px;
        height: 304px;
        /* outline: #000 solid 6px; */
    }
    /* table query */
   
  }
/* for extra small media querys */
@media (min-width: 1730px)   {
    .video_mobile1_laptop {
        position: absolute;
        left: 154px;
        top: 302px;
        width: 588px;
        height: 473px;
        /* outline: #000 solid 6px; */
    }
    .labtop_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        /*background-image: url(./img_new/laptop_new.png);*/
        background-size: 90% 57%;
        /* width: 351px; */
        height: 696px;
        background-repeat: no-repeat;
    }
    /* for desktop media  */
    .video_mobile1_desktop {
        position: absolute;
        left: 95px;
        top: 228px;
        width: 778px;
        height: 354px;
        /* outline: #000 solid 6px; */
  }
  .desktop_bg_img {
    width: 100%;
    /* float: right; */
    /* height: 900px; */
    /*background-image: url(./img_new/computer_desktop.png);*/
    background-size: 83% 82%;
    /* width: 351px; */
    height: 696px;
    background-repeat: no-repeat;
}
/* tablet media query */
.video_mobile1_tablet {
    position: absolute;
    left: 83px;
    top: 25%;
    width: 62%;
    height: 34%;
    /* outline: #000 solid 6px; */
}
}
@media (min-width: 1830px)   {
    .video_mobile1_laptop {
        position: absolute;
        left: 154px;
        top: 302px;
        width: 588px;
        height: 473px;
        /* outline: #000 solid 6px; */
    }
    .labtop_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        /*background-image: url(./img_new/laptop_new.png);*/
        background-size: 90% 57%;
        /* width: 351px; */
        height: 696px;
        background-repeat: no-repeat;
    }
    /* for desktop media  */
    .video_mobile1_desktop {
        position: absolute;
        left: 95px;
        top: 228px;
        width: 778px;
        height: 354px;
        /* outline: #000 solid 6px; */
  }
  .desktop_bg_img {
    width: 100%;
    /* float: right; */
    /* height: 900px; */
    /*background-image: url(./img_new/computer_desktop.png);*/
    background-size: 83% 82%;
    /* width: 351px; */
    height: 696px;
    background-repeat: no-repeat;
}
/* tablet media query */
.video_mobile1_tablet {
    position: absolute;
    left: 83px;
    top: 25%;
    width: 62%;
    height: 34%;
    /* outline: #000 solid 6px; */
}
.tablet_bg_img {
    width: 100%;
    /* float: right; */
    /* height: 900px; */
    /*background-image: url(./img_new/navigator_strata_1_tablet_frame.png);*/
    background-size: 85% 65%;
    /* width: 351px; */
    height: 696px;
    background-repeat: no-repeat;
}
}

  /* mobile responsive media query  */
  @media (min-width: 280px) and (max-width: 330px) {
  .video_mobile1 {
    position: absolute;
    left: 6px;
    top: 18%;
    width: 80%!important;
    height: 84%;
    /* outline: #000 solid 6px; */
  }
}
  @media (min-width: 280px) and (max-width: 767px) {
    .mobile_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        /*background-image: url(./img_new/mobile-3640069_960_720.png);*/
        background-size: 96% 86%;
        /* width: 500px; */
        height: 731px;
        background-repeat: no-repeat;
    }
    .video_mobile1 {
        position: absolute;
        left: 6px;
        top: 18%;
        width: 83%;
        height: 84%;
        /* outline: #000 solid 6px; */
    }
    /* for laptop mobile responsive */
    .video_mobile1_laptop {
        position: absolute;
        left: 13%;
        top: 28%;
        width: 95%;
        height: 392px;
        /* outline: #000 solid 6px; */
    }
    .labtop_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        /*background-image: url(https:s3.amazonaws.com/uploads.hipchat.com/464662/5786475/Bsb0v88YqPiYoI2/laptop-full.png);*/
        background-size: 100% 52%;
        /* width: 500px; */
        height: 843px;
        background-repeat: no-repeat;
    }
    .video_inner_tag1_laptop {
        position: relative;
        top: -178px;
        left: 7%;
        width: 65%;
        height: 89%;
        object-fit: fill;
    }
    .mgt_top_minus130per{
        margin-top: -120%;
    }
    /* for desktop mobile responsive */
    .video_mobile1_desktop {
        position: absolute;
        left: 8%;
        top: 8%;
        width: 84%;
        height: 55%;
        /* outline: #000 solid 6px; */
    }
    .desktop_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        background-image: url();
        background-size: 100% 100%;
        /* width: 351px; */
        height: 696px;
        background-repeat: no-repeat;
    }
    .video_inner_tag1_desktop {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 90%;
        object-fit: fill;
    }
    /* for tablet media mobile responsive */
    .video_mobile1_tablet {
        position: absolute;
        left: 10%;
        top: 10%;
        width: 72%;
        height: 56%;
        /* outline: #000 solid 6px*/
    }
    .tablet_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        background-image: url();
        background-size: 100% 65%;
        /* width: 351px; */
        height: 696px;
        background-repeat: no-repeat;
    }
    .video_inner_tag1_tablet {
        position: absolute;
        left: 17px;
        width: 90%;
        height: 90%;
        object-fit: fill;
    }
    .mrg_top_minus_50per{
        margin-top: -50%;
    }
    .disable_web_media{
        display: block
    }
    .disable_mobile_media{
        display: none
    }
  }
  @media (min-width: 1025px){
  .disable_web_media{
      display: none
  }
}

/* media tablets  */
@media (min-width: 768px) and (max-width: 1024px) {
    
        .video_mobile1_laptop {
            position: absolute;
            left: 13%;
            top: 31%;
            width: 57%;
            height: 23%;
            /* outline: #000 solid 6px; */
    }
    .labtop_bg_img {
        width: 100%;
        /* float: right; */
        /* height: 900px; */
        background-image: url();
        background-size: 88% 44%;
        /* width: 351px; */
        height: 696px;
        background-repeat: no-repeat;
    }
    .mgrt_color_third {
        margin-top: 0%;
    }
    .video_mobile1_desktop {
        position: absolute;
        left: 11%;
        top: 13%;
        width: 582px;
        height: 312px;
        /* outline: #000 solid 6px; */
    }
    .video_mobile1_tablet {
        position: absolute;
        left: 10%;
        top: 16%;
        width: 440px;
        height: 277px;
        /* outline: #000 solid 6px; */
    }
    .disable_mobile_media{
        display: none
    }
    .disable_web_media{
        display: block
    }
  }